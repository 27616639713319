import React, { useState, useEffect } from "react";
import MessageBox from "../../../components/Common/MessageBox";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import {
  getLiveFixturesBySportIdCall,
  getLiveSportsCall,
} from "../../../helpers/repository";
import { Fixtures } from "../../../components/Common/Fixtures";
import { withRouter } from "react-router-dom";
import { LiveFilters } from "../../../components/Common/LiveFilters";

function SportsDetailsLive(props) {
  const getId = () => {
    const urlParams = new URLSearchParams(props.location.search);
    let id = +urlParams.get("id");
    return id;
  };
  const [fixtures, set_fixtures] = useState([]);
  const [all_leagues, set_all_leagues] = useState(true);
  const [matchTime, setMatchTime] = useState("Today");
  const [marketChange, setMarketChange] = useState("1");
  const [loadingMessage, setLoadingMessage] = useState("No Data Available");
  const [loadMoreMessage, setLoadMoreMessage] = useState("Load More Data");
  const [next_page_url, set_next_page_url] = useState(null);
  const [sportId, setSportId] = useState(getId());
  const [trigger, setTrigger] = useState(0);
  const [liveInfo, setLiveInfo] = useState([]);

  const Sports = useSelector((state) => state?.Sports.Index ?? []);

  useEffect(() => {
    getLiveSportsCall().then((res) => {
      let d = res?.data?.data;
      setLiveInfo(d);
    });
  }, []);

  const handleClick = () => {
    setLoadingMessage("Loading Data...");
    if (![undefined, null, ""].includes(sportId) && ![undefined, null, "1"].includes(marketChange)) {
      getLiveFixturesBySportIdCall(
        `/v2/live/sports/${sportId}/fixtures`,
        matchTime,
        marketChange
      )
        .then((res) => {
          set_fixtures(res?.data?.data?.data);
        })
        .finally(() => {
          setLoadingMessage("");
        });
    }
  };

  useEffect(() => {
    handleClick();
  }, [marketChange]);

  return (
    <>
      <div className="BLM-tab-pane active" id="BLM-sports-livenow">
        <div className="BLM-sportsPage-livenow">
          <LiveFilters
            sport_id={sportId}
            fixtures={fixtures}
            isForLeague={false}
            setLoadingMessage={setLoadingMessage}
            setLoadMoreMessage={setLoadMoreMessage}
            set_fixtures={set_fixtures}
            setMarketChange={setMarketChange}
            getData={getLiveFixturesBySportIdCall}
            url={`/v4/live/sports/${sportId}/fixtures`}
            perPage={10}
            next_page_url={next_page_url}
            set_next_page_url={set_next_page_url}
            trigger={trigger}
            matchTime={matchTime}
            setMatchTime={setMatchTime}
          />

          <div className="BLM-inplay-content">
            {![undefined, null, ""].includes(loadingMessage) ? (
                <MessageBox data={loadingMessage} />
            ) :fixtures.length ? (
              <Fixtures
                marketId={marketChange}
                fixtures={fixtures}
                pageName={"live"}
                loadingMessage={loadingMessage}
                loadMoreMessage={loadMoreMessage}
                getData={getLiveFixturesBySportIdCall}
                url={`/v2/live/sports/${sportId}/fixtures`}
                perPage={10}
                next_page_url={next_page_url}
                isLive={true}
              />
            ) : (
              <MessageBox data={loadingMessage} />
            )}
          </div>
          {next_page_url && (
            <div
              onClick={() => {
                setTrigger(trigger + 1);
              }}
            >
              <MessageBox data={loadMoreMessage} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default withRouter(SportsDetailsLive);