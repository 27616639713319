import React, {useEffect, useState} from "react";
import {getJackpotResultByIdCall} from "../../helpers/repository";
import {useParams} from "react-router-dom";
import PageHeader from "../../components/Common/PageHeader";
import MessageBox from "../../components/Common/MessageBox";
import {FixturesJackpot} from "../../components/Common/FixtureJackpot";
import {get} from "lodash";
import {FixturesJackpotResult} from "../../components/Common/FixturesJackpotResult";

function CompleteJackpot(props) {
    const {id} = useParams();
    const [info, setInfo] = useState(false);
    const [jackpot, setJackpot] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState("Loading Data...");
    const [loadMoreMessage, setLoadMoreMessage] = useState("Load More Data");
    const [trigger, setTrigger] = useState(0);
    var fixtureCount = 0
    useEffect(() => {
        fetchData();
    }, [trigger]);
    const fetchData = () => {
        getJackpotResultByIdCall(id,trigger)
            .then((res) => {
                if(trigger!=0){
                    let new_fixtures = get(res, "data.data[0].fixtures.data", []);
                    let combined_fixtures = jackpot?.fixtures?.data.concat(new_fixtures);
                    res.data.data[0].fixtures.data=combined_fixtures;
                    setJackpot(jackpot);
                }
                setJackpot(res?.data?.data[0]);
                if(res?.data?.data[0].length==0){
                    setLoadingMessage("No Data Available");
                }
                else{
                    setLoadingMessage(null);
                }
            })
    }
    return (
        <>
            <div className="BLM-commonPage">
                <div className="BLM-commonPage-content-full-width BLM-common">
                    <div className="NP-jackpot NP-JP-previousResults">
                        <div className="BLM-pageHeader">
                                <div
                                    className="BLM-pageBackButton"
                                    onClick={() => {
                                        props.history.push(`/jackpot?tab=2`);
                                    }}
                                >
                                    <div className="BLM-iconSvg">
                                        <svg
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 24 24"
                                            style={{ enableBackground: "new 0 0 24 24" }}
                                            xmlSpace="preserve"
                                        >
                                            <path d="M10.4,22.4l1.8-1.8l-7.3-7.3H24v-2.6H5l7.3-7.3l-1.8-1.8L0,12L10.4,22.4z" />
                                        </svg>
                                    </div>
                                </div>
                            <div className="BLM-pageHeader-title">Completed Jackpot</div>
                        </div>
                        {/*<PageHeader title={"Completed Jackpot"} showBack={true}/>*/}
                        <div className="NP-JP-market-group">
                            <div className="NP-JP-market-group__header NP-JP-market__header-1x2">
                                <div className="NP-JP-market-group__header-left">
                                    <img src="assets/images/SVG/trophy.svg" />
                                    <span className="NP-JP-market-header__title">
                    {jackpot?.name}
                  </span>
                                </div>
                                <div className="NP-JP-market-group__header-right"></div>
                            </div>
                        </div>
                        {/*<div className="NP-JP-cardBanner previousResults">*/}
                        {/*    <div className="NP-JP-cardBanner-container">*/}
                        {/*        <div className="NP-accordion NP-prizeMoneyAccordion collapsed">*/}
                        {/*            <div className="NP-accordion-header NP-cardBanner__prizeMoney-container">*/}
                        {/*                <div className="NP-cardBanner__prizeMoney">*/}
                        {/*                    <span className="NP-currency">{currency} </span>*/}
                        {/*                    <span className="NP-prizeValue">{jackpot?.max_price}</span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div*/}
                        {/*                className={`NP-JP-cardBanner-row NP-accordion-content ${*/}
                        {/*                    info ? "" : "d-none"*/}
                        {/*                }`}*/}
                        {/*            >*/}
                        {/*                <div className="NP-cardBanner__description">*/}
                        {/*                    Prizes for runners up and most predictions - Jackpot will*/}
                        {/*                    be shared if more than 1 winner*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="NP-cardBanner__footer">*/}
                        {/*            <div className="NP-cardBanner__bonusExtraInfo">*/}
                        {/*                <div className="NP-cardBanner__extraInfo">*/}
                        {/*                    <span>Market Type:{jackpot?.market}</span>*/}
                        {/*                    <br />*/}
                        {/*                    <span>Minimum Stake:{currency} {jackpot?.price_per_ticket}</span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="BLM-home-upcoming-content">

                            {![undefined, null, ""].includes(loadingMessage) ? (
                                <MessageBox data={loadingMessage} />
                            ) : jackpot?.fixtures?.data.length ? (
                                <FixturesJackpotResult
                                    fixtures={jackpot?.fixtures?.data}
                                    perPage={10}
                                    loadingMessage={loadingMessage}
                                    loadMoreMessage={loadMoreMessage}
                                />

                            ) : (
                                <MessageBox
                                    data={`No Data Available`}
                                />
                            )}
                        </div>

                        {jackpot?.fixtures?.next_page_url && (
                            <div
                                onClick={() => {
                                    setTrigger(trigger + 1);
                                }}
                            >
                                <MessageBox data={loadMoreMessage} />
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </>
    )
}
export default CompleteJackpot;