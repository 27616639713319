import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_ALL_LOCATIONS } from "./actionTypes";
import { getAllLocationsCall } from "../../helpers/repository";
import { getAllLocationsResponseSuccess } from "./actions";
import { showErrorMessages } from "../../components/Common/Utils/helpers";

function* attemptGetAllLocations() {
  try {
    const response = yield call(getAllLocationsCall);
    yield put(getAllLocationsResponseSuccess(response.data.data));
  } catch (error) {
    showErrorMessages(error);
  }
}

function* LocationsSaga() {
  yield takeEvery(GET_ALL_LOCATIONS, attemptGetAllLocations);
}

export default LocationsSaga;
