import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_ALL_SPORTS } from "./actionTypes";
import { getAllSportsCall } from "../../helpers/repository";
import { getAllSportsResponseSuccess } from "./actions";
import { showErrorMessages } from "../../components/Common/Utils/helpers";
import store from "../index";
import { getFixturesBySportId } from "../Fixtures/actions";

function* attemptGetAllSports() {
  try {
    const response = yield call(getAllSportsCall);
    const footballId = response.data.data.data.find(
      (sport) => sport?.name == "Football"
    )?.id;
    yield put(getAllSportsResponseSuccess(response.data.data));
    // yield store.dispatch(getFixturesBySportId(footballId));
  } catch (error) {
    showErrorMessages(error);
  }
}

function* SportsSaga() {
  yield takeEvery(GET_ALL_SPORTS, attemptGetAllSports);
}

export default SportsSaga;
