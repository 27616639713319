import React from "react";

function MessageBox(props) {
  return (
    <>
      <div className="BLM-content">
        <div className={`BLM-msgBox`} data-livenowleagueid={9999}>
          <div className="BLM-msgBox-container">{props?.data}</div>
        </div>
      </div>
    </>
  );
}

export default MessageBox;
