import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_ALL_STATIC_PAGES } from "./actionTypes";
import { getAllStaticPagesCall, API_URL } from "../../helpers/repository";
import { getAllStaticPagesResponseSuccess } from "./actions";
import { showErrorMessages } from "../../components/Common/Utils/helpers";

function* attemptGetAllStaticPages() {
  try {
    const response = yield call(getAllStaticPagesCall);
    yield put(getAllStaticPagesResponseSuccess(response.data.data));
  } catch (error) {
    showErrorMessages(error);
  }
}

function* StaticPagesSaga() {
  yield takeEvery(GET_ALL_STATIC_PAGES, attemptGetAllStaticPages);
}

export default StaticPagesSaga;
