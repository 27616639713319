import {
  GET_ALL_LEAGUES,
  GET_ALL_LEAGUES_RESPONSE_SUCCESS,
} from "./actionTypes";

export const getAllLeagues = () => {
  return {
    type: GET_ALL_LEAGUES,
  };
};
export const getAllLeaguesResponseSuccess = (payload) => ({
  type: GET_ALL_LEAGUES_RESPONSE_SUCCESS,
  payload: payload,
});
