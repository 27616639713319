import React from "react";

function ButtonLoader() {
  return (
    <div className="BLM-loader">
      <div className="BLM-dot-pulse">
        <span />
        <span />
      </div>
    </div>
  );
}

export default ButtonLoader;
