import { get } from "lodash";
import React, { useState, useEffect } from "react";
import { getNoDataMessage } from "../../helpers/utils";
import { useSelector } from "react-redux";
import {
  getAllLeaguesBySport,
  getFixturesBySportIdCall,
  getFixturesByLeagueIdCall,
  getLiveSports,
  getMarketsBySportId,
} from "../../helpers/repository";
import { showMessage } from "./Toaster";

export const LiveFilters = (props) => {
  const [sports, setSports] = useState([]);
  const [marketChange, setMarketChange] = useState(null);
  useEffect(() => {
    getLiveSports(sucessGetLiveSports, errorGetLiveSports);
  }, []);

  const sucessGetLiveSports = (d) => {
    setSports(d);
  };
  const errorGetLiveSports = (e) => {};
  const Sports = useSelector((s) => s?.Sports?.Index?.data ?? []);
  const [leagues, setLeagues] = useState([]);
  const [league, setLeague] = useState(
      props.isForLeague ? props.league_id : "AllLeagues"
  );
  const [matchTime, setMatchTime] = useState("Today");

  const [markets, setMarkets] = useState([]);

  useEffect(() => {
    if (markets.length) {
      setMarketChange(markets[0].bb_id);
    }
  }, [markets]);
  useEffect(() => {
    if (props.sport_id)
      getMarketsBySportId(props.sport_id, successGetMarkets, errorGetMarkets);
  }, [props.sport_id]);

  useEffect(() => {
    props.setMarketChange(marketChange);
  }, [marketChange]);

  const successGetMarkets = (data) => {
    setMarkets(data);
  };
  const errorGetMarkets = (error) => {
    showMessage("error", error);
  };
  useEffect(() => {
    if (props.isForLeague) setLeague(props.league_id);
  }, [props.league_id]);

  useEffect(() => {
    if (props.sport_id) {
      getAllLeaguesBySport(
          props.sport_id,
          successGetLeaguesBySport,
          errorGetLeaguesBySport
      );
    }
  }, [props.sport_id]);

  const infiniteScroll = () => {
    // End of the document reached?
    if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
    ) {
    }
  };

  const fetchData = () => {
    if (props?.next_page_url != null) {
      props.setLoadingMessage("Loading Data...");
      if (props?.next_page_url != null) {
        props.setLoadingMessage("Loading Data...");

        if (![undefined, null, ""].includes(props.sport_id)) {
          props?.setLoadMoreMessage("Loading Data...");
          props
              .getData(
                  props?.next_page_url,
                  matchTime,
                  marketChange,
                  props?.perPage,
                  "&"
              )
              .then((res) => {
                let new_fixtures = get(res, "data.data.data", []);
                let combined_fixtures = props.fixtures.concat(new_fixtures);
                props.set_fixtures(combined_fixtures);
                props?.set_next_page_url(
                    get(res, "data.data.next_page_url", null)
                );
                if (res?.data?.data?.data?.length == 0)
                  props.setLoadingMessage("No Data Available");
              })
              .finally(() => {
                props?.setLoadMoreMessage("Load More Data");
              });
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.trigger]);

  // useEffect(() => {
  //   if(markets.length == 0) {
  //     props.setLoadingMessage("No Data Available");
  //   }
  // }, [markets]);

  useEffect(() => {
    window.addEventListener("scroll", infiniteScroll);
  }, []);

  const successGetLeaguesBySport = (d) => {
    setLeagues(d);
  };
  const errorGetLeaguesBySport = () => {
    console.log("error");
  };

  useEffect(() => {
    if (marketChange) {
      if (league == "AllLeagues") {
        props.setLoadingMessage("Loading Data...");
        props
            .getData(props.url, matchTime, marketChange, props?.perPage)
            .then((res) => {
              props.set_fixtures(res?.data?.data?.data);
              props?.set_next_page_url(res?.data?.data?.next_page_url);
              if (res?.data?.data?.data?.length == 0)
                props.setLoadingMessage("No Data Available");
            })
            .catch((e) => {
              console.log(e);
            })
            .finally(() => {
              // props.setLoadingMessage(null);
            });
      } else {
        props.setLoadingMessage("Loading Data...");

        getFixturesByLeagueIdCall(league, matchTime, marketChange)
            .then((res) => {
              props.set_fixtures(res?.data?.data?.data);
              if (res?.data?.data?.data?.length == 0)
                props.setLoadingMessage("No Data Available");
            })
            .catch((e) => {
              console.log("error");
            })
            .finally(() => {
              // props.setLoadingMessage(null);
            });
      }
    }
  }, [league, matchTime, props.sport_id, marketChange]);

  return (
      <>
        <div className="BLM-filterDropdowns ">
          <div className="BLM-select">
            <select
                id="ddn_leagues"
                onChange={() => {
                  // setLeague(window.$("#ddn_leagues").val());
                  setLeague(document.getElementById("ddn_leagues").value);
                }}
            >
              <option value="AllLeagues">All Leagues</option>
              {leagues
                  ? _.sortBy(leagues, "name")?.map((l, index) => (
                      <>
                        <option
                            key={index}
                            value={`${l?.bb_id}`}
                            data-league={`${l?.bb_id}`}
                            selected={league == l.bb_id}
                        >
                          {l?.name} -{l?.category?.name}
                        </option>
                      </>
                  ))
                  : null}
            </select>
          </div>
          <div className="BLM-select">
            <select
                id="ddn_upcomingmarketss"
                onChange={(e) => {
                  setMarketChange(e.target.value);
                }}
            >
              {markets?.map((m) => {
                return (
                    <option selected={marketChange == m.bb_id} value={m.bb_id}>
                      {m.name?.replaceAll("1x2", "1X2")}{" "}
                      {/*{m.name}{" "}*/}
                      {m.special_bet_value && m.special_bet_value != "-1"
                          ? "[" + m.special_bet_value + "]"
                          : ""}
                    </option>
                );
              })}
            </select>
          </div>
        </div>
      </>
  );
};
