import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_ALL_JACKPOTS } from "./actionTypes";
import { getAllJackpotsCall } from "../../helpers/repository";
import { getAllJackpotsResponseSuccess } from "./actions";
import { showErrorMessages } from "../../components/Common/Utils/helpers";

function* attemptGetAllJackpots() {
  try {
    const response = yield call(getAllJackpotsCall);
    yield put(getAllJackpotsResponseSuccess(response.data.data));
  } catch (error) {
    if(error?.response?.data?.code!="restrict"){
      showErrorMessages(error);
    }
  }
}

function* JackpotsSaga() {
  yield takeEvery(GET_ALL_JACKPOTS, attemptGetAllJackpots);
}

export default JackpotsSaga;
