
export const startLoading = (user) => {
    return {
      type: 'START_LOADING',
    };
};

export const stopLoading = (user) => {
    return {
      type: 'STOP_LOADING',
    };
};