import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTab, setActiveFooterTab } from "../../store/actions";
import { withRouter } from "react-router-dom";
import { setBets } from "../../store/Betslip/actions";

function Footer(props) {
  const isLoggedIn = sessionStorage.getItem("token") ? true : false;
  const activeTab = useSelector(
    (state) => state?.CommonState?.activeTab ?? false
  );
  const activeFooterTab = useSelector(
    (state) => state?.CommonState?.activeFooterTab ?? false
  );

  const Bets = useSelector((state) => state?.Betslip?.bets ?? []);
  const dispatch = useDispatch();
  useEffect(() => {
    const storedBets = localStorage.getItem("betsplace");
    if (storedBets) {
      dispatch(setBets(JSON.parse(storedBets)));
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("betsplace", JSON.stringify(Bets));
  }, [Bets]);

  return (
    <>
      <div className="BLM-mobile-footer">
        <ul className="">
          <li
            className={`${
              ["home-upcoming"].includes(activeTab) ? "active" : ""
            }`}
            onClick={() => {
              dispatch(setActiveFooterTab("home"));
              dispatch(setActiveTab("home-upcoming"));
              props.history.push("/sports");
            }}
          >
            <div className="BLM-iconSvg BLM-homeIcon">
              <img src="/assets/images/PNG/home.svg" style={{
                "width": "18px",
                "margin-top": "3px"}} />
            </div>
            <div>Home</div>
          </li>
          <li
            onClick={() => {
              props.history.push("/sports");
              dispatch(setActiveTab("home-live-now"));
              dispatch(setActiveFooterTab("home-live-now"));
            }}
            className={`${
              ["home-live-now"].includes(activeTab) ? "active" : ""
            }`}
          >
            <div className="BLM-iconSvg BLM-inplayIcon">
              <img src="/assets/images/PNG/live.svg" style={{
                "width": "18px",
                "margin-top": "3px"}} />
            </div>
            <div>Live</div>
          </li>
          <li
            id="BLM-betSlip-Button"
            className="BLM-betSlip-active"
            onClick={(event) => {
              dispatch(setActiveFooterTab("betslip"));
              props.history.push("/betSlip");
            }}
            data-footer-betslip="Betslip"
          >
            <a href="javascript:void(0);" target>
              <div className="BLM-iconSvg BLM-betslipIcon">
                <img src="/assets/images/PNG/betslip.svg" style={{
                  "width": "18px",
                  "margin-top": "3px"}} />
                
              </div>
              <div>Betslip</div>
              <span
                className="BLM-bets-count"
                id="getBetsCountDisplay"
                data-footer-betslip="Betslip"
              >
                {Bets.length}
              </span>
            </a>
          </li>
          {isLoggedIn && (
            <li
              onClick={() => {
                props.history.push("/MyBets");
                dispatch(setActiveFooterTab("my-bets"));
                dispatch(setActiveTab(""));
              }}
              className={`${
                ["my-bets"].includes(activeFooterTab) ? "active" : ""
              }`}
            >
              <div className="BLM-iconSvg BLM-inplayIcon">
                <i className="sb-icon_myBets" />
              </div>
              <div>My Bets</div>
            </li>
          )}

          <li
            id="open_fc_widget"
          >
             <a className="btn BLM-betSlip-Button" href="https://api.whatsapp.com/send?phone=260770959909&text=I%20need%20support" style={{"color": "white"}} target="_blank"
   rel="noopener noreferrer" >
              <div className="BLM-iconSvg BLM-chatIcon">
                {/* <i className="sb-icon_chat" /> */}
                <img src="/assets/images/PNG/whatsapp_icon.svg" style={{
                  "width": "22px"}} />
              </div>
            </a>
            <div id="status">WhatsApp</div>
          </li>
        </ul>
      </div>
    </>
  );
}

export default withRouter(Footer);
