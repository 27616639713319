import {
  API_ERROR,
  SHOW_SIDEBAR,
  SET_ACTIVE_TAB,
  SET_ACTIVE_FOOTER_TAB,
} from "./actionTypes";
export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
export const showSideBar = (showSideBar) => {
  return {
    type: SHOW_SIDEBAR,
    payload: showSideBar,
  };
};
export const setActiveTab = (payload) => {
  return {
    type: SET_ACTIVE_TAB,
    payload: payload,
  };
};
// export const setActiveFooterTab = (payload) => {
//   // <!--Start of Tawk.to Script-->
//   var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
//   (function(){
//     var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
//     s1.async=true;
//     s1.src='https://embed.tawk.to/62fdeb3e54f06e12d88f4e52/1gantts4t';
//     s1.charset='UTF-8';
//     s1.setAttribute('crossorigin','*');
//     s0.parentNode.insertBefore(s1,s0);
//   })();
// // <!--End of Tawk.to Script-->
//   return {
//     type: SET_ACTIVE_FOOTER_TAB,
//     payload: payload,
//   };
// };
export const setActiveFooterTab = (payload) => {
  // <!--Start of Tawk.to Script-->
  // var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
  // (function(){
  //   var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
  //   s1.async=true;
  //   s1.src='https://embed.tawk.to/63fe141631ebfa0fe7efd112/1gqc7sumk';
  //   s1.charset='UTF-8';
  //   s1.setAttribute('crossorigin','*');
  //   s0.parentNode.insertBefore(s1,s0);
  // })();

// <!--End of Tawk.to Script-->
  return {
    type: SET_ACTIVE_FOOTER_TAB,
    payload: payload,
  };
};
