import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { apiError } from "../../store/actions";
import FieldValidation from "../../components/Common/FieldValidation";
import { changePin } from "../../store/Auth/actions";
import { changePinCall } from "../../helpers/repository";
import { showMessage } from "../../components/Common/Toaster";
import { showErrorMessages } from "../../components/Common/Utils/helpers";
import PageHeader from "../../components/Common/PageHeader";

function ChangePin(props) {
  const initialErrorState = {
    existing_pin: "",
    new_pin: "",
    confirm_new_pin: "",
  };

  const [existing_pin, set_existing_pin] = useState("");
  const [new_pin, set_new_pin] = useState("");
  const [confirm_new_pin, set_confirm_new_pin] = useState("");
  const [error, set_error] = useState(initialErrorState);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(apiError(""));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (new_pin !== confirm_new_pin) {
    //   set_error((prevState) => {
    //     return {
    //       ...prevState,
    //       existing_pin: window.GetLocalizedErrorMessages(
    //         "regConfirmNotmatcherrMsg"
    //       ),
    //     };
    //   });
    // }
    // dispatch(changePin({ password: new_pin }));
    let data = {};
    data.old_password = existing_pin;
    data.password = new_pin;
    data.password_confirmation = confirm_new_pin;
    changePinCall(data, success, errorCallback);
  };

  const success = (res) => {
    showMessage("success", res?.data?.message);
  };

  const errorCallback = (error) => {
    showErrorMessages(error);
  };

  return (
    <>
      <PageHeader title={"Change Pin"} showBack={false} />
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content BLM-common">
          <div className="BLM-loginRegister">
            <div className="BLM-content">
              <form onSubmit={handleSubmit}>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">
                    Enter existing 6 digit PIN:
                  </div>
                  <div
                    className="BLM-form-control BLM-form-control-pin"
                    id="regConfirmErrorMessageClass"
                  >
                    <input
                      type="password"
                      id="regConfirm"
                      placeholder="XXXXXX"
                      maxLength={6}
                      required
                      onChange={(e) => {
                        set_error(initialErrorState);
                        set_existing_pin(e.target.value);
                      }}
                    />
                  </div>
                  <div className="BLM-form-errorMsg"></div>
                  <div className="BLM-form-errorMsg d-none">
                    <span id="regLengthErrorMessage" />
                  </div>
                </div>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Enter New 6 digit PIN:</div>
                  <div
                    className="BLM-form-control BLM-form-control-pin"
                    id="regPinErrorMessageClass"
                  >
                    <input
                      type="password"
                      id="regPass"
                      placeholder="XXXXXX"
                      maxLength={6}
                      required
                      onChange={(e) => {
                        set_error(initialErrorState);
                        set_new_pin(e.target.value);
                      }}
                    />
                  </div>
                  <div className="BLM-form-errorMsg"></div>
                </div>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Confirm New 6 digit PIN:</div>
                  <div
                    className="BLM-form-control BLM-form-control-pin"
                    id="regConfirmErrorMessageClass"
                  >
                    <input
                      type="password"
                      id="regConfirm"
                      placeholder="XXXXXX"
                      maxLength={6}
                      required
                      onChange={(e) => {
                        set_error(initialErrorState);
                        set_confirm_new_pin(e.target.value);
                      }}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {error?.existing_pin ? (
                      <FieldValidation errorMessage={error?.existing_pin} />
                    ) : null}
                  </div>
                </div>

                <button
                  className="btn BLM-btn BLM-btnSecondary active BLM-btnLarge"
                  id="disableRegisterButtonClick"
                  type="submit"
                >
                  <span className="BLM-btnTxt">Change Pin</span>
                </button>
              </form>
              <div className="space-10" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePin;
