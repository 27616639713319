import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import PageHeader from "../../components/Common/PageHeader";
import { setActiveTab } from "../../store/actions";
import {showMessage} from "../../components/Common/Toaster";
import {callDepositeAmount} from "../../helpers/repository";
import {currency} from "../../helpers/constants";
import ReactModal from "react-modal";
function Deposits() {
  const dispatch = useDispatch();
  const [num, setNumber] = useState(10);
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [openModel, setModelOpen] = useState(false);
  useEffect(() => {
    dispatch(setActiveTab(""));
  }, []);
  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setNumber(value);
  };

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "300px!important",
      // backgroundColor: "#272727",
      color: "black",
      padding: "0px"
    },
  };

  useEffect(() => {
    let timerId;
    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);

    }
  }, [countDown, runTimer]);

  const _handleModalOpen = (e) => {
    setModelOpen(true);
  }

  const _handleSubmitDeposite = (e) => {
    setModelOpen(false);
    setRunTimer((t) => !t)
    let data = {};
    data.amount=num;
    data.user_id=JSON.parse(localStorage.getItem("user"))?.user?.id;
    data.currency=currency;
    data.mobile_prefix="260";
    callDepositeAmount(data).then((res) => {
      showMessage("success",res?.data?.message)
      // console.log(res)
    }).catch((e) => {
      showMessage("error",e?.response?.data?.error)
    });
  }


  return (
    <>

      <PageHeader title={"Deposit"} showBack={false} />
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content BLM-common">
          <div className="space-3"></div>
          {/* <div className="row">
            <p
              className="text-center
            "
            >
              Your Account Balance: <b className="text-danger">{currency} 0</b>
            </p>
          </div> */}
          <div className="space-5"></div>

          <div className="row">
            <div className="col-3">
              <button className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary ${num=='10' ? "active" : ''}`}
              onClick={() => {
                setNumber('10');
              }}>
                <span className="BLM-btnTxt">10</span>
              </button>
            </div>
            <div className="col-3">
              <button className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary ${num=='25' ? "active" : ''}`}
                      onClick={() => {
                        setNumber('25');
                      }}>
                <span className="BLM-btnTxt">25</span>
              </button>
            </div>
            <div className="col-3">
              <button className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary ${num=='50' ? "active" : ''}`}
                      onClick={() => {
                        setNumber('50');
                      }}>
                <span className="BLM-btnTxt">50</span>
              </button>
            </div>
            <div className="col-3">
              <button className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary ${num=='100' ? "active" : ''}`}
                      onClick={() => {
                        setNumber('100');
                      }}>
                <span className="BLM-btnTxt">100</span>
              </button>
            </div>
          </div>
          <div className="space-10"></div>

          <div className="row">
            <div className="col-lg-12">
              <div className="BLM-form-group">
                <div className="BLM-form-label">Enter Amount to Deposit:</div>
                <div
                  className="BLM-form-control BLM-form-control-pin"
                  id="errorMessagePassClass"
                >
                  <input
                    type="text"
                    id="userPass"
                    onkeyup="return numberMobile(event)"
                    onkeypress="return enterUserName(event)"
                    placeholder="XXXXX"
                    required
                    pattern="[0-9]*"
                    value={num} onChange={handleChange}
                  />
                </div>
                <div className="BLM-form-errorMsg">
                  <span id="displayErrorMessage"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="space-5"></div>
          <div className="row">
            <p>
              After clicking on "Deposit", please wait for the popup on your
              mobile phone and confirm the payment by inputting your Mobile money PIN.
              Kindly ensure you have enough balance in your Mobile Money  account to
              complete the transaction.
            </p>
          </div>

          <div className="space-5"></div>
          {
            runTimer ?
                <button
                    className="btn BLM-btnSuccess active BLM-btnLarge"
                    id="disableLoginButtonClick">
                  <span className="spinner-grow spinner-grow-sm"></span>
                </button> :
                <button
                    className="btn BLM-btnSuccess active BLM-btnLarge"
                    id="disableLoginButtonClick"
                    onClick={() => {
                      _handleModalOpen();
                    }}
                >
                  <span className="BLM-btnTxt">Deposit</span>
                </button>
          }
          <div className="row d-flex mt-5">
            <div className="col-md-4 text-center">
              <img src="/assets/images/PNG/airtel-money.png"/>
            </div>
            <div className="col-md-4 text-center">
              <img src="/assets/images/PNG/MTN-money.png"/>
            </div>
            <div className="col-md-4 text-center">
              <img src="/assets/images/PNG/Zam-money.png"/>
            </div>

          </div>


          {
            <div className="row img_row">
              <ReactModal
                  isOpen={openModel}
                  style={customModalStyles}
                  // className="modal-content-react"
              >

                <div className="modal-content">
                  <div className="modal-header">
                    <div className="modal-title h4">DEPOSIT</div>
                    <button type="button" className="btn-close" aria-label="Close" onClick={()=> {
                      setModelOpen(false);
                    }}></button>
                  </div>
                  <div className="modal-body">

                    <p style={{fontSize:"17px"}}>Are you sure you want to deposit {num} amount?</p>

                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" style={{padding: "8px 17px"}}  onClick={() => {
                      _handleSubmitDeposite();
                    }}>Yes</button>
                    <button type="button" className="btn btn-danger" style={{padding: "8px 17px"}}  onClick={() => {
                      setModelOpen(false);
                    }}>Cancel</button>
                  </div>
                </div>
              </ReactModal>
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default Deposits;
