import { all, fork } from "redux-saga/effects";
import AuthSaga from "./Auth/saga";
import PromotionsSaga from "./Promotions/saga";
import StaticPagesSaga from "./StaticPages/saga";
import JackpotsSaga from "./jackpots/saga";
import LeaguesSaga from "./Leagues/saga";
import LocationsSaga from "./Locations/saga";
import SportsSaga from "./Sports/saga";
import FixturesSaga from "./Fixtures/saga";
import FixtureDetailSaga from "./FixtureDetail/saga";
import BannersSaga from "./Banners/saga";
import ProductsSaga from "./Products/saga";

export default function* rootSaga() {
  yield all([fork(AuthSaga)]);
  yield all([fork(PromotionsSaga)]);
  yield all([fork(StaticPagesSaga)]);
  yield all([fork(JackpotsSaga)]);
  yield all([fork(LeaguesSaga)]);
  yield all([fork(LocationsSaga)]);
  yield all([fork(SportsSaga)]);
  yield all([fork(FixturesSaga)]);
  yield all([fork(FixtureDetailSaga)]);
  yield all([fork(BannersSaga)]);
  yield all([fork(ProductsSaga)]);
}
