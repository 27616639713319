import React, { useEffect, useState } from "react";
import {
  getFixturesByLeagueIdCall,
  getFixturesBySportIdCall,
} from "../../helpers/repository";
import { useSelector, useDispatch } from "react-redux";
import { convertUTCDateToLocalDate } from "../../helpers/utils";
import { Fixtures } from "../../components/Common/Fixtures";
import MessageBox from "../../components/Common/MessageBox";
import { Filters } from "../../components/Common/Filters";
import { useLocation } from "react-router-dom";
import { getLeagueNameByLeagueId } from "../../helpers/utils";
import PageHeader from "../../components/Common/PageHeader";
const LeagueDetails = (props) => {
  const [leagueByFixture, setLeagueByFixture] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [marketChange, setMarketChange] = useState(null);
  const [fixtures, set_fixtures] = useState([]);
  const [next_page_url, set_next_page_url] = useState(null);
  const [matchTime, setMatchTime] = useState("All");
  const [league, setLeague] = useState("AllLeagues");
  const [resultTab, setResultTab] = useState("today");
  const [trigger, setTrigger] = useState(0);
  const [loadMoreMessage, setLoadMoreMessage] = useState("Load More Data");
  const urlParams = new URLSearchParams(props.location.search);
  const search = useLocation().search;
  let id = new URLSearchParams(search).get("id");
  // let sport_id = +urlParams.get("sport_id");
  let sport_id = new URLSearchParams(search).get("sport_id");

  useEffect(() => {
    setLoadingMessage("Loading Data...");
    getFixturesByLeagueIdCall(id)
        .then((res) => {
          //   set_fixtures(res?.data?.data?.data);
          setLeagueByFixture(res?.data?.data?.data);
        })
        .finally(() => {
          setLoadingMessage("");
        });
  }, [id, marketChange]);
  const Leagues = useSelector((state) => state?.Leagues.Index ?? [])?.data;
  //
  // const callLeagues = () => {
  //   setLoadingMessage("Loading Data...");
  //
  //   getFixturesByLeagueIdCall(ddl_leagues.value)
  //     .then((res) => {
  //       //   set_fixtures(res?.data?.data?.data);
  //       setLeagueByFixture(res?.data?.data?.data);
  //     })
  //     .finally(() => {
  //       setLoadingMessage("");
  //     });
  // };
  const handleMarketChange = () => {
    setMarketChange(document.getElementById("ddl_markets").value);
  };

  return (
      <>
        <div className="BLM-commonPage">
          <div className="BLM-commonPage-content-full-width BLM-common">
            <div>
              <PageHeader
                  title={getLeagueNameByLeagueId(Leagues, id)}
                  showBack={true}
              />
              <Filters
                  sport_id={sport_id}
                  league_id={id}
                  fixtures={fixtures}
                  isForLeague={true}
                  setLoadingMessage={setLoadingMessage}
                  set_fixtures={set_fixtures}
                  setMarketChange={setMarketChange}
                  getData={getFixturesBySportIdCall}
                  url={`/v4/sports/${sport_id}/fixtures`}
                  next_page_url={next_page_url}
                  set_next_page_url={set_next_page_url}
                  matchTime={matchTime}
                  setMatchTime={setMatchTime}
                  setLeague={setLeague}
                  league={league}
                  resultTab={resultTab}
                  setResultTab={setResultTab}
                  toHideFilter={true}
                  isLeagueSet={true}
                  trigger={trigger}
                  setLoadMoreMessage={setLoadMoreMessage}
              />
              {loadingMessage ? (
                  <MessageBox data={loadingMessage} />
              ) : fixtures?.length ? (
                  <Fixtures marketId={marketChange} fixtures={fixtures} />
              ) : (
                  <MessageBox
                      data={
                        // loadingMessage??
                        "Currently, there are no matches avaiable for this league"
                      }
                  />
              )}
              {next_page_url && (
                  <div
                      onClick={() => {
                        setTrigger(trigger + 1);
                      }}
                  >
                    <MessageBox data={loadMoreMessage} />
                  </div>
              )}
            </div>
          </div>
        </div>
      </>
  );
};
export default LeagueDetails;
