import React, { useEffect, useState } from "react";
import PageHeader from "../../components/Common/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import {
    getFixtureById,
    getLiveFixtureById,
} from "../../store/FixtureDetail/actions";
import { useLocation, withRouter } from "react-router-dom";
import _, { get, isEmpty, result } from "lodash";
import MessageBox from "../../components/Common/MessageBox";
import {
    getParticipantByPosition,
    getDayName,
    oddsInRow,
    getUrlLastPart,
    getUrlParameter,
} from "../../components/Common/Utils/helpers";
import { convertUTCDateToLocalDate } from "../../helpers/utils";
import { setBets } from "../../store/Betslip/actions";
import { SPORTS_WITH_MARKETS } from "../../helpers/constants";
import Countdown from "react-countdown";
function FixtureDetails(props) {
    const urlLastPart =
        getUrlLastPart(props.location.search).split("/").pop() == "live"
            ? true
            : false;
    const dispatch = useDispatch();
    const Bets = useSelector((state) => state?.Betslip?.bets ?? []);
    const isLoading = useSelector((state) => state.Common.loading);
    const [participant_one, set_participant_one] = useState({});
    const [participant_two, set_participant_two] = useState({});
    const [marketChange, setMarketChange] = useState("1");
    const [selected_button, set_selected_button] = useState("All");
    const [timeStatus, setTimeStatus] = useState("");
    const [message, setMessage] = useState("Loading Data..");
    const handleMarketChange = () => {
        setMarketChange(document.getElementById("ddn_otherMarkets").value);
    };

    useEffect(() => {
        if (isLoading) {
        } else {
        }
    }, [isLoading]);

    useEffect(
        () => {
            let timer1 = setTimeout(() =>
                (!isLoading && isEmpty(FixtureDetail)) ? setMessage('No Data Available') : '',1000);
            return () => {
                clearTimeout(timer1);
            };
        },
        []
    );

    const FixtureDetail = useSelector(
        (state) => state?.FixtureDetail.Index ?? {}
    );


    const search = useLocation().search;
    const _renderMatchHalf = () => {
        let text = FixtureDetail?.status ?? "";
        text = text.replaceAll("1p", "1st Half");
        text = text.replaceAll("2p", "2nd Half");
        text = text.replaceAll("1p_ot", "1st Half Extra");
        text = text.replaceAll("ot_ht", "Paused");
        text = text.replaceAll("2p_ot", "2nd Half Extra");
        text = text.replaceAll("1q", "1st Quater");
        text = text.replaceAll("2q", "2nd Quater");
        text = text.replaceAll("3q", "2nd Quater");
        text = text.replaceAll("4q", "2nd Quater");
        text = text.replaceAll("pause1", "1st Pause");
        text = text.replaceAll("pause2", "2nd Pause");
        text = text.replaceAll("pause3", "3rd Pause");
        text = text.replaceAll("pause3", "3rd Pause");
        text = text.replaceAll("ended", "Ended");
        text = text.replaceAll("pen", "Penalty Shoot-out");
        text = text.replaceAll("not_started", "Not Started Yet");
        text = text.replaceAll("after_otended", "Ended");
        text = text.replaceAll("awaiting_otended", "Waiting for overtime to start");
        text = text.replaceAll("awaiting_penpen", "Waiting for overtime to start");
        return text;
    };
    useEffect(() => {
        let id = new URLSearchParams(search).get("id").split("/")[0];
        let isLive =
            new URLSearchParams(search)?.get("is_live")?.split("/")[0] ?? false;
        if (isLive) {
            dispatch(getLiveFixtureById(id));
        } else {
            dispatch(getFixtureById(id));
        }
    }, []);

    const checkIfOddIsSelected = (f, fm, odd) => {
        if (Bets.length && odd) {
            return (
                Bets.find((b) => b._id == f._id)
                    ?.fixture_markets?.find((fmd) => fmd._id == fm._id)
                    ?.fixture_market_odds?.find((o) => o._id == odd._id)?.selected ??
                false
            );
        }
        return false;
    };

    useEffect(() => {
        if (!isEmpty(FixtureDetail)) {
            set_participant_one(FixtureDetail.competitors[0]);
            set_participant_two(FixtureDetail.competitors[1]);
        }
    }, [FixtureDetail]);

    const getFixtureType = () => {
        return FixtureDetail?.livescore?.scoreboard?.current_period?.type
            ?.description;
    };
    const getTimeInMinutes = () => {
        let fixtureTime = Math.floor(
            FixtureDetail?.livescore?.scoreboard?.time / 60
        );
        return fixtureTime;
    };

    let orderArray=[
        '1x2',
        'Total',
        'Double Chance',
        'Draw No Bet',
        'Both teams to score',
        'Odd/Even',
        'Booking 1x2',
        'Correct score',
        'Corner 1x2',
        '1st half - 1x2',
        '1st half - total',
        '1st half - double chance',
        '1st half - draw no bet',
        '1st half - both teams to score',
        '1st half - odd/even',
        '1st half - correct score',
        '1st half - booking 1x2',
        '2nd half - 1x2',
        '2nd half - total',
        '2nd half - double chance',
        '2nd half - draw no bet',
        '12nd half - both teams to score',
        '2nd half - odd/even',
        '2nd half - correct score',
        'Which team to score',
        'Highest scoring half',
        'Halftime/fulltime correct score',
        'Halftime/fulltime',
        '1x2 and both teams to score',
        'Exact bookings',
        'Sending off',
        'Corner range',
        'Last corner',
        '{competitor1} corner range',
        '{competitor2} corner range',
        'Last goal',
        'Winning margin',
        '{competitor1} win to nil',
        '{competitor2} win to nil',
    ];


    if(FixtureDetail.fixture_markets){
        const FixtureDetail1 = [...FixtureDetail.fixture_markets
        ].sort((a, b) => {

            const indexA = orderArray.findIndex(id => id === a.market.name || String(id).toLowerCase() === String(a.market.name).toLowerCase());
            const indexB = orderArray.findIndex(id => id === b.market.name || String(id).toLowerCase() === String(b.market.name).toLowerCase());


            if (indexA === -1 && indexB === -1) {
                return a.fixture_bb_id
                    .localeCompare(b.fixture_bb_id
                        , undefined, { sensitivity: 'base' } ); // Sort alphabetically if both items are unmatched
            }

            if (indexA === -1) {
                return 1; // a is unmatched, move it down
            }

            if (indexB === -1) {
                return -1; // b is unmatched, move it down
            }


            return indexA - indexB;
        });
        FixtureDetail.fixture_markets=FixtureDetail1;
    }
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            setTimeStatus("completed");
            // Render a completed state
            return <div>Completed</div>;
        } else {
            // Render a countdown
            return (
                <span>
          {hours}:{minutes}:{seconds}
        </span>
            );
        }
    };

    const _addToBetslip = (fixture, fixtureMarket, odd) => {
        let copyFixture = _.cloneDeep(fixture);
        let oddsData = copyFixture.fixture_markets
            .find((fixtureMarketData) => fixtureMarketData._id == fixtureMarket._id)
            .fixture_market_odds.map((odd) => {
                odd.selected = false;
                return odd;
            });

        copyFixture.fixture_markets.find(
            (fixtureMarketData) => fixtureMarketData._id == fixtureMarket._id
        ).odds = oddsData;

        copyFixture.fixture_markets
            .find((fixtureMarketData) => fixtureMarketData._id == fixtureMarket._id)
            .odds.find((oddData) => oddData._id == odd._id).selected = true;

        let foundFixture = Bets.find((fix) => fix._id == fixture._id);
        if (foundFixture) {
            let d = _.cloneDeep(Bets);
            let index = d.findIndex((i) => i._id == fixture._id);
            if (index > -1) {
                d[index] = copyFixture;
            }
            dispatch(setBets(d));
        } else {
            dispatch(setBets([...Bets, copyFixture]));
        }
    };

    const _renderScoreBoard = () => {
        const results = FixtureDetail?.score?.split(":") ?? [];

        return (
            <>
                <div className="BLM-scoreContainer">
          <span className="BLM-score BLM-home">
            {!isEmpty(results[0]) ? results[0] : "-"}
          </span>
                    <span className="BLM-seperater">:</span>
                    <span className="BLM-score BLM-away">
            {!isEmpty(results[1]) ? results[1] : "-"}
          </span>
                </div>
            </>
        );
    };

    const _removeOddFromBetslip = (fixture) => {
        dispatch(setBets(_.cloneDeep(Bets).filter((fd) => fd._id != fixture._id)));
    };

    const getColumns = (l) => {
        if (l > 3) {
            return "col-4";
        }
        return "col";
    };

    const _renderOddName = (f, odd) => {
        if (odd.player) {
            return odd.player.name;
        }
        let text = odd?.market_spec?.name ?? odd?.market_spec?.bb_id;
        text = text.replaceAll("{$competitor1}", 'Home');
        text = text.replaceAll("{$competitor2}", 'Away');
        text = text.replaceAll("{total}", odd.special_bet_value);
        if (text == "o") {
            return "Over";
        } else if (text == "u") {
            return "Under";
        }
        return text;
    };

    const _renderMarketName = (f, value) => {
        let text = value.name;

        text = text.replaceAll("{$competitor1}", 'Home');
        text = text.replaceAll("{$competitor2}", 'Away');
        return (
            text +
            (value.special_bet_value && value.special_bet_value != "-1"
                ? "[" + value.special_bet_value + "]"
                : "")
        );
    };
    const _renderOddsData = (f, fixtureMarket, marketName) => {
        let classFor = ["1x2","Double chance","1st half - 1x2","1x2 (incl. overtime)","Exact sets","1st half - double chance","Booking 1x2","Corner 1x2","1st half - booking 1x2","2nd half - 1x2","2nd half - double chance","Corner range","Last corner","Last goal"]
        let marketNameForAllowValue= ["Total","1st half - total","2nd half - total"];

        if (FixtureDetail?.tournament?.sport_id == 12 || FixtureDetail?.tournament?.sport_id == 1) {
            classFor.push("Highest scoring half");
        }

        let myOdds = fixtureMarket.fixture_market_odds;
        let tempOddNames = myOdds?.map((o) => o?.name);
        tempOddNames = [...new Set(tempOddNames ?? [])];
        return tempOddNames?.map((ton) => {
            return (
                <div className={`row ${getColumns(tempOddNames.length)}`}>
                    {_.sortBy(myOdds,'special_bet_value')
                        ?.filter((o) => o?.name == ton)
                        .map((odd,index) => {
                            return (
                                (String(odd.special_bet_value).split('.').pop() != '25' && String(odd.special_bet_value).split('.').pop() != '75' && (marketNameForAllowValue.indexOf(marketName) > -1 ? ((odd.special_bet_value  % 1 === 0.5 && odd.special_bet_value >= 1) ? true : false) : true))
                                    ?

                                    <div key={index} className={"inline-div col-" + (classFor.indexOf(marketName) > -1 ? '4' : '6')}>
                                        <button
                                            className={
                                                checkIfOddIsSelected(f, fixtureMarket, odd)
                                                    ? `btn BLM-btnOdds  BLM-btnOutComeOdds active`
                                                    : `btn BLM-btnOdds BLM-btnOutComeOdds`
                                            }
                                            disabled={odd ? (odd.value == "OFF" ? true : false) : true}
                                            // className="btn BLM-btnOdds BLM-btnOutComeOdds"
                                            individualevent-odds-incdec="N"
                                            id="highlightBet_328088483"
                                            onClick={() => {
                                                if (checkIfOddIsSelected(f, fixtureMarket, odd)) {
                                                    _removeOddFromBetslip(f);
                                                } else {
                                                    _addToBetslip(f, fixtureMarket, odd);
                                                }
                                            }}
                                        >
                    <span className="BLM-outcome">
                      {_renderOddName(f, odd)}
                        {odd?.special_bet_value && odd.special_bet_value != "-1"
                            ? "[" + odd.special_bet_value + "]"
                            : ""}
                        {/* ${
                      ![undefined, null, ""].includes(odd?.base_line)
                        ? "[" + odd?.base_line + "]"
                        : ""
                    }`} */}
                    </span>
                                            <span className="BLM-odds">
                      {odd.value == "OFF"
                          ? odd.value
                          : parseFloat(odd?.value).toFixed(2)}{" "}
                    </span>
                                        </button>
                                    </div>
                                    : ''

                            );
                        })}
                </div>
            );
        });
    };

    const _getmarkets = () => {
        let sport_id = FixtureDetail?.tournament?.sport?.bb_id;
        let all_matches = {
            '16' : [
                '1x2',
                'Winner',
                'Total',
                'Will there be overtime',
                'Halftime/fulltime',
                '1st half - 1x2',
                '1st half - draw no bet',
                '1st half - total',
                'Highest scoring quarter',
                '1x2 (incl. overtime)',
                'Total field goals made (incl. overtime)',
            ],
            '117' : [
                'Winner',
                'Total',
                'Will the fight go the distance',
                'Winner and exact rounds',
            ],
            '138' : [
                'Winner',
                'Total',
                'Will the fight go the distance',
                'Winner and exact rounds',
            ],
            '10' : [
                '1x2',
                'Winner',
                'Total',
                'Will the fight go the distance',
                'Winner and exact rounds',
            ],
            '1' : [
                '1x2',
                'Total',
                'Double Chance',
                'Draw No Bet',
                'Both teams to score',
                'Odd/Even',
                'Booking 1x2',
                'Correct score',
                'Corner 1x2',
                '1st half - 1x2',
                '1st half - total',
                '1st half - double chance',
                '1st half - draw no bet',
                '1st half - both teams to score',
                '1st half - odd/even',
                '1st half - correct score',
                '1st half - booking 1x2',
                '2nd half - 1x2',
                '2nd half - total',
                '2nd half - double chance',
                '2nd half - draw no bet',
                '12nd half - both teams to score',
                '2nd half - odd/even',
                '2nd half - correct score',
                'Which team to score',
                'Highest scoring half',
                'Halftime/fulltime correct score',
                'Halftime/fulltime',
                '1x2 and both teams to score',
                'Exact bookings',
                'Sending off',
                'Corner range',
                'Last corner',
                '{competitor1} corner range',
                '{competitor2} corner range',
                'Last goal',
                'Winning margin',
                '{competitor1} win to nil',
                '{competitor2} win to nil',
            ],
            '21' : [
                '{$competitor1}',
                '{$competitor2}',
            ]
        };

        return (all_matches[sport_id]) ? all_matches[sport_id] : [];
    }


    const _renderFixtureDetail = (filter) => {

        //for market filtration
        // const market = _getmarkets()
        // const filtered_market = [];
        // market.forEach(el => filtered_market.push(filter.find(e => e.market.name == el)));
        // const results = filtered_market.filter(element => {
        //   return element !== undefined;
        // });

        // return results.map((fixture_market, index) => {

        return filter.map((fixture_market, index) => {
            return (
                <div className="BLM-marketBox BLM-accordion ">
                    <div
                        className="BLM-marketBox-header BLM-accordion-header BLM-arrowAfter"
                        id={`market${index + 1}`}
                        onClick={() => {
                            window.getMarketAccordian(`market${index + 1}`);
                        }}
                    >

                        <div className="BLM-marketName">
              <span>
                {_renderMarketName(FixtureDetail, fixture_market?.market)}
              </span>
                        </div>
                    </div>
                    <div className="BLM-accordion-content">
                        <div className="BLM-marketBox-content">
                            {/*<ul*/}
                            {/*  className={`BLM-marketOutComeOddsList row`}*/}
                            {/*  style={{ display: "flex" }}*/}
                            {/*>*/}
                            {_renderOddsData(FixtureDetail, fixture_market, _renderMarketName(FixtureDetail, fixture_market?.market))}
                            {/*</ul>*/}
                        </div>
                    </div>
                </div>
            );
        });
    };
    return (
        <>
            <>
                {!isLoading && !isEmpty(FixtureDetail) ? (
                    <PageHeader
                        title={`${participant_one?.name} v ${participant_two?.name}`}
                        showBack={true}
                    />
                ) : null}

                <div className="BLM-commonPage">
                    <div className="BLM-commonPage-content-full-width BLM-common BLM-eventPage BLM-soccer">
                        {!isLoading && !isEmpty(FixtureDetail) ? (
                            <>
                                <div className="BLM-eventPage-scoreBoard-container">
                                    <div className="BLM-scoreBoard BLM-soccer">
                                        <div className="BLM-leagueInfo-container">
                                            <div className="BLM-leagueInfo">
                                                <i className="bl-icon-sport-soccer" />{" "}
                                                <span>{FixtureDetail?.tournament?.sport?.name}</span> /{" "}
                                                <span onclick="getIndividualleagueMatches('Football', 'English Premier League', '')">
                          {FixtureDetail?.tournament?.name}
                        </span>
                                            </div>
                                            {/*<div className="BLM-smsCode">*/}
                                            {/*  Event Code: {FixtureDetail.bb_id}*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className="BLM-scoreBoard-content">
                                            <div className="BLM-scoreInfo">
                                                <div className="BLM-scoreLayout-1">
                                                    <div className="BLM-teamContainer">
                                                        <div className="BLM-teamName BLM-home">
                                                            {participant_one?.name}
                                                        </div>
                                                    </div>
                                                    {_renderScoreBoard()}

                                                    <div className="BLM-teamContainer">
                                                        <div className="BLM-teamName BLM-away">
                                                            {participant_two?.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="BLM-kickOff">
                                                {_renderMatchHalf()} :
                                                {FixtureDetail.match_time_minutes ??
                                                new Date(
                                                    parseInt(
                                                        FixtureDetail?.match_date?.$date?.$numberLong
                                                    )
                                                ).toLocaleString()}
                                                {}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="BLM-eventPage-content">
                                    {selected_button == "All"
                                        ? _renderFixtureDetail(FixtureDetail?.fixture_markets)
                                        : _renderFixtureDetail(
                                            FixtureDetail?.fixture_markets?.filter(
                                                (fm) => fm?.market_id == marketChange
                                            )
                                        )}
                                </div>
                            </>
                        ) : (
                            <MessageBox data={message} />
                        )}
                    </div>
                </div>
            </>
        </>
    );
}
export default withRouter(FixtureDetails);