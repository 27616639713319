import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import ReactModal from "react-modal";
import { withRouter } from "react-router-dom";
import { BlockedGames, getAviatorGameCall } from "../../helpers/repository";

function Aviator(props) {
  const [iframeURL, setIframeURL] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    BlockedGames()
      .then((res) => {
        setIframeURL(res);
      })
      .catch((e) => {
        if (e?.response?.data?.code == "restrict") {
          setShow(true);
          // props.history.push("/comming-soon");
        }
      });
  }, []);

  useEffect(() => {
    _openGameUrl();
  }, []);

  const _openGameUrl = () => {
    $(".ReactModal__Overlay").show();
    getAviatorGameCall("aviator", successGetGameUrl, errorGetGameUrl);
  };
  const successGetGameUrl = (res) => {
    setIframeURL(decodeURIComponent(res));
  };

  const errorGetGameUrl = (error) => {
    showMessage("error", error);
  };
  return (
    <>
      {show ? (
        <div style={{ textAlign: "center", padding: "88px" }}>
          <h5>No Data Available</h5>
        </div>
      ) : (
        <div
          className="BLM-commonPage"
          style={{ "background-color": "#353535" }}
        >
          <div
            className="BLM-commonPage-content BLM-common"
            style={{ padding: "0px 22px 22px 22px", marginBottom: "unset" }}
          >
            <div className="row img_row img_row1">
              <ReactModal
                // style={customStyles}
                isOpen={true}
                onRequestClose={() => props.history.goBack()}
                className="Turbo_game"
              >
                <Iframe
                  url={iframeURL}
                  width="450px"
                  height="685px"
                  id="myId"
                  className="myClassname"
                  display="initial"
                  position="relative"
                />
              </ReactModal>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default withRouter(Aviator);
