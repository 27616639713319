import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PageHeader from "../../components/Common/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { getAllPromotions } from "../../store/Promotions/actions";
import { imagePath } from "../../helpers/constants";
import MessageBox from "../../components/Common/MessageBox";

function Promotions(props) {
  const dispatch = useDispatch();
  const [loadingMessage, setLoadingMessage] = useState("Loading Data...");

  useEffect(() => {
    dispatch(getAllPromotions());
  }, []);
  const Promotions = useSelector((state) => state?.promotions.Index ?? []);

  useEffect(() => {
    if (Promotions?.length > 0) setLoadingMessage(null);
  }, [Promotions]);

  return (
    <>
      <PageHeader title={"Promotions"} showBack={true} />
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content-full-width BLM-common">
          <div className="BLM-promotions">
            {![null, ""].includes(loadingMessage) ? (
              <MessageBox data={loadingMessage} />
            ) : (
              Promotions.map((promotion, index) => {
                return (
                  <>
                    <div className="BLM-promotion-item" key={index}>
                      <div className="BLM-promo-banner">
                        <img src={imagePath("banners", promotion?.banner)} />
                      </div>
                      <div className="BLM-promo-content BLM-content">
                        <div className="BLM-promo-title">
                          {`${promotion?.header}`}
                        </div>
                        <div className="BLM-promo-description">
                          <p>{`${promotion?.short_body}`}</p>
                        </div>
                        <button
                          className="btn BLM-btnSecondary BLM-btnLarge active"
                          onClick={() => {
                            props.history.push(
                              `promotion-details/item?id=${promotion.id}`
                            );
                          }}
                        >
                          Details
                        </button>
                      </div>
                    </div>
                  </>
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Promotions);
