import {
  GET_ALL_PROMOTIONS,
  GET_ALL_PROMOTIONS_RESPONSE_SUCCESS,
} from "./actionTypes";

export const getAllPromotions = () => {
  return {
    type: GET_ALL_PROMOTIONS,
  };
};
export const getAllPromotionsResponseSuccess = (payload) => ({
  type: GET_ALL_PROMOTIONS_RESPONSE_SUCCESS,
  payload: payload,
});
