import {
    GET_ALL_PRODUCTS,
    GET_ALL_PRODUCTS_RESPONSE_SUCCESS,
} from "./actionTypes";

export const getAllProducts = () => {
    return {
        type: GET_ALL_PRODUCTS,
    };
};
export const getAllProductsResponseSuccess = (payload) => ({
    type: GET_ALL_PRODUCTS_RESPONSE_SUCCESS,
    payload: payload,
});
