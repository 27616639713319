import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  SOCIAL_LOGIN,
  LOGIN_ATTEMPT_RESPONSE_ERROR,
  RESET_STATE,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  CHANGE_PIN,
  CHANGE_PIN_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
} from "./actionTypes";
import { API_ERROR } from "../actionTypes";
export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const registerUser = (user, history, cb) => {
  return {
    type: REGISTER_USER,
    payload: { user, history },
    cb: cb,
  };
};

export const registerUserSuccess = (user) => {
  return {
    type: REGISTER_USER_SUCCESS,
    payload: user,
  };
};

export const changePin = (password) => {
  return {
    type: CHANGE_PIN,
    payload: password,
  };
};

export const ChangePinSuccess = (user) => {
  return {
    type: CHANGE_PIN_SUCCESS,
    payload: user,
  };
};

export const updateProfile = (data) => {
  return {
    type: UPDATE_PROFILE,
    payload: data,
  };
};

export const updateProfileSuccess = (user) => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: user,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const loginWithEmailAndPasswordResponseERROR = (payload) => ({
  type: LOGIN_ATTEMPT_RESPONSE_ERROR,
  payload: payload,
});
