import { combineReducers } from "redux";


const loading = (state = false, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return true
    case 'STOP_LOADING':
      return false
    default:
      return state
  }
};

const Common = combineReducers({
  loading,
});
export default Common;
