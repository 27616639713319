import React, { useEffect } from "react";
function CommingSoon(props) {
    return (
        <>
            <div className="BLM-footer">
                <div className="BLM-footer-container">
                    <h1>Site is under maintenance</h1>
                </div>
            </div>
        </>
    );
}

export default CommingSoon;
