import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import TabMenu from "../../components/Common/TabMenu";
import HomeUpcoming from "./HomeUpcoming";
import LiveNow from "./LiveNow";
import { setActiveTab, setActiveFooterTab } from "../../store/actions";
import { getLiveSportsCall } from "../../helpers/repository";
import Banners from "../../components/Common/Banners";
import SignupPopup from "../Register/SignupPopup";
function Home(props) {
  const dispatch = useDispatch();
  const [showSignupModal, setSignupShowModal] = useState(false);
  const [showInfoModal, setInfoShowModal] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const isLoggedIn = sessionStorage.getItem("token") ? true : false;
  useEffect(() => {
    if (!dontShowAgain) {
      // Cookies.remove('dontShowModalAgain');
      const lastModalTime = Cookies.get("lastModalTime");
      const dontShowModalAgain = Cookies.get("dontShowModalAgain");
      const currentTime = new Date().getTime();

      if (isLoggedIn) {
        const deposit_through_popup = localStorage.getItem(
          "depositThroughPopup"
        );
        const reg_through_popup = JSON.parse(localStorage.getItem("user"))?.user
          ?.reg_through_popup;
        if (
          reg_through_popup == 1 &&
          deposit_through_popup == 0 &&
          (!lastModalTime || currentTime - lastModalTime >= 1 * 2 * 60 * 1000)
        ) {
          setInfoShowModal(true);
        }
      }

      // 24 * 60 * 60 * 1000
      else if (
        !dontShowModalAgain &&
        (!lastModalTime || currentTime - lastModalTime >= 24 * 60 * 60 * 1000)
      ) {
        setSignupShowModal(true);
      }
    }
  }, [dontShowAgain, isLoggedIn]);

  const handleDontShowAgainChange = (event) => {
    setDontShowAgain(event.target.checked);
  };

  const active_tab = useSelector(
    (state) => state?.CommonState?.activeTab ?? "home-upcoming"
  );
 
  const Banner = useSelector((state) => state?.Banner?.Index).filter(
    (banner) => banner["page_name"] == "Home Page"
  );

  const [live_sports_count, set_live_sports_count] = useState(0);
  const [fixtures, set_fixtures] = useState([]);
  const [next_page_url, set_next_page_url] = useState(null);
  const [marketChange, setMarketChange] = useState("1");

  const [matchTime, setMatchTime] = useState("all");

  useEffect(() => {
    getLiveSportsCall()
      .then((res) => {
        let sum = res?.data?.data
          .map((o) => o.live)
          .reduce((a, c) => {
            return a + c;
          });
        set_live_sports_count(sum);
      })
      .catch((e) => {
        if (e?.response?.data?.code == "restrict") {
          props.history.push("/comming-soon");
        }
      });
  }, []);

  const tabs = [
    {
      id: "home-upcoming",
      title: "Upcoming",
      activeClass: active_tab == "home-upcoming" ? "active" : "",
      tabHighlightText: "",
      tabHighlightId: "",
    },
    {
      id: "home-live-now",
      title: "Live Now",
      activeClass: active_tab == "home-live-now" ? "active" : "",
      tabHighlightText: `(${live_sports_count})`,
      tabHighlightId: "liveScoreCount",
    },
  ];
  return (
    <>
      {/* {(showSignupModal || showInfoModal) && (
        <SignupPopup
          dontShowAgain={dontShowAgain}
          handleDontShowAgainChange={handleDontShowAgainChange}
          setSignupShowModal={setSignupShowModal}
          showSignupModal={showSignupModal}
          showInfoModal={showInfoModal}
          setInfoShowModal={setInfoShowModal}
        />
      )} */}
      <Banners Banner={Banner} />
      <TabMenu
        tabs={tabs}
        showSearch
        marketChange={marketChange}
        setMarketChange={setMarketChange}
        matchTime={matchTime}
        setMatchTime={setMatchTime}
        active_tab={active_tab}
        set_active_tab={setActiveTab}
        dispatch={dispatch}
        setActiveFooterTab={setActiveFooterTab}
        activeFooterTab={active_tab}
        fixtures={fixtures}
        set_fixtures={set_fixtures}
        next_page_url={next_page_url}
        set_next_page_url={set_next_page_url}
      />
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content-full-width BLM-common">
          <div className="BLM-tabs-content">
            {active_tab == "home-upcoming" && (
              <HomeUpcoming
                matchTime={matchTime}
                setMatchTime={setMatchTime}
                marketChange={marketChange}
                setMarketChange={setMarketChange}
                fixtures={fixtures}
                set_fixtures={set_fixtures}
                next_page_url={next_page_url}
                set_next_page_url={set_next_page_url}
              />
            )}
            {active_tab == "home-live-now" && (
              <LiveNow
                marketChange={marketChange}
                setMarketChange={setMarketChange}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Home);
