import {
  GET_FIXTURES_BY_SPORT_ID,
  GET_FIXTURES_BY_SPORT_ID_RESPONSE_SUCCESS,
} from "./actionTypes";

export const getFixturesBySportId = (id) => {
  return {
    type: GET_FIXTURES_BY_SPORT_ID,
    id: id,
  };
};
export const getFixturesBySportIdsResponseSuccess = (payload) => ({
  type: GET_FIXTURES_BY_SPORT_ID_RESPONSE_SUCCESS,
  payload: payload,
});
