import { combineReducers } from "redux";
import get from "lodash/get";
import {
  API_ERROR,
  SHOW_SIDEBAR,
  SET_ACTIVE_TAB,
  SET_ACTIVE_FOOTER_TAB,
} from "./actionTypes";
import { initialState } from "./constants";
import auth from "./Auth/reducer";
import promotions from "./Promotions/reducer";
import staticPages from "./StaticPages/reducer";
import jackpots from "./jackpots/reducer";
import Leagues from "./Leagues/reducer";
import Locations from "./Locations/reducer";
import Sports from "./Sports/reducer";
import FixturesBySport from "./Fixtures/reducer";
import FixtureDetail from "./FixtureDetail/reducer";
import Betslip from "./Betslip/reducer";
import Common from "./common/reducer";
import Banner from "./Banners/reducer";
import Products from "./Products/reducer";

const CommonState = (state = initialState, action) => {
  const payload = get(action, "payload", null);
  switch (action.type) {
    case API_ERROR:
      return payload;
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSideBar: payload,
      };
    case SET_ACTIVE_TAB:
      return { ...state, activeTab: payload };
    case SET_ACTIVE_FOOTER_TAB:
      return { ...state, activeFooterTab: payload };

    default:
      return state;
  }
};
const rootReducer = combineReducers({
  CommonState,
  auth,
  promotions,
  staticPages,
  jackpots,
  Leagues,
  Locations,
  Sports,
  FixturesBySport,
  FixtureDetail,
  Betslip,
  Common,
  Banner,
  Products,
});

export default rootReducer;
