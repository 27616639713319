import { GET_ALL_PRODUCTS_RESPONSE_SUCCESS } from "./actionTypes";
import { combineReducers } from "redux";

const Index = (state = [], action) => {
    const { payload } = action;
    switch (action.type) {
        case GET_ALL_PRODUCTS_RESPONSE_SUCCESS:
            return payload;

        default:
            return state;
    }
};

const Product = combineReducers({
    Index,
});

export default Product;
