import parse from "html-react-parser";
import React from "react";
import PageHeader from "../../components/Common/PageHeader";
import { getUrlLastPart } from "../../components/Common/Utils/helpers";
import { useSelector, useDispatch } from "react-redux";

function StaticPage(props) {
  const Details =
    useSelector((state) => state?.staticPages.Index ?? []).find(
      (sp) => sp["page_type"] == getUrlLastPart().replaceAll("-", " ")
    )?.details ?? "";

  return (
    <>
      <PageHeader title={getUrlLastPart().replaceAll("-", " ")} />
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content BLM-common">
          {parse(Details)}
        </div>
      </div>
    </>
  );
}

export default StaticPage;
