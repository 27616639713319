// "http://dev.betstamp.site"
// export const SERVER_URL = "http://192.168.18.13:8000";
export const SERVER_URL = "https://dev.betta1.co.zm";

// export const SERVER_URL = "http://localhost/betstamp-projects/betta1-zambia/betstamp-backend/public";

// export const SERVER_URL =
//   "http://4734-2400-adc5-1d4-9e00-9360-1157-1a4f-278a.ngrok.io";
export const API_URL = `${SERVER_URL}/api`;
export const imagePath = (folderName, file) =>
  `${SERVER_URL}/images/${folderName}/${file}`;
export const currency = "ZMW";

export const ODD_MARKETS = [
  {
    name: "1X2",
    odds_name: ["1", "X", "2"],
    odds: ["1", "X", "2"],
    id: 1,
  },
  {
    name: "Double Chance",
    odds_name: ["1 or X", "2 or X", "1 or 2"],
    odds: ["1X", "X2", "12"],
    id: 7,
  },
  {
    name: "Both Teams To Score",
    odds_name: ["Yes", "No"],
    odds: ["Yes", "No"],
    id: 13,
  },
  {
    name: "Over/Under",
    odds_name: ["Over", "Under"],
    odds: ["Over", "Under"],
    id: 2,
  },
  {
    name: "Highest Opening Partnership",
    odds_name: ["1", "X", "2"],
    odds: ["1", "X", "2"],
    id: 196,
  },
  {
    name: "12",
    odds_name: ["1", "2"],
    odds: ["1", "2"],
    id: 36,
  },
];

export const SPORTS_WITH_MARKETS = [
  {
    name: "Football",
    id: 10,
    markets: [
      {
        name: "1X2 -Full Time (excl.Over time)",
        odds_name: ["1", "X", "2"],
        odds: ["1", "X", "2"],
        id: 1,
      },
      {
        name: "BOTH TEAMS TO SCORE (GG/NG)",
        odds_name: ["Yes", "No"],
        odds: ["Yes", "No"],
        id: 13,
      },
      {
        name: "DOUBLE CHANCE",
        odds_name: ["1 or X", "2 or X", "1 or 2"],
        odds: ["1X", "X2", "12"],
        id: 7,
      },
      {
        name: "TOTAL GOALS OVER/UNDER - FULL TIME",
        odds_name: ["Over", "Under"],
        odds: ["Over", "Under"],
        id: 2,
      },
      {
        name: "1x2 -First Half",
        odds_name: ["1", "X", "2"],
        odds: ["1", "X", "2"],
        id: 221,
      },
      {
        name: "12",
        odds_name: ["1", "2"],
        odds: ["1", "2"],
        id: 36,
      },
      {
        name: "Double Chance - Half Time",
        odds_name: ["1 or X", "2 or X", "1 or 2"],
        odds: ["1X", "X2", "12"],
        participants: true,
        id: 18,
      },
      {
        name: "TOTAL GOALS OVER/UNDER - HALF TIME",
        odds_name: ["Over", "Under"],
        odds: ["Over", "Under"],
        id: 58,
      },
      {
        name: "HALF TIME/FULL TIME",
        odds_name: [
          "1/1",
          "1/X",
          "1/2",
          "X/1",
          "X/X",
          "X/2",
          "2/1",
          "2/X",
          "2/2",
        ],
        odds: ["1/1", "1/X", "1/2", "X/1", "X/X", "X/2", "2/1", "2/X", "2/2"],
        participants: true,
        id: 58,
      },
      {
        name: "CORRECT SCORE -FULL TIME",
        odds_name_participants: true,
        odds_name: ["1", "X", "2"],
        id: 6,
      },
    ],
  },
];
