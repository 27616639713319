import React from "react";
import { Redirect } from "react-router-dom";
import Home from "../pages/Home/Home";
import IconBoxicons from "../components/Common/Icons/IconBoxicons";
import IconFontawesome from "../components/Common/Icons/IconFontawesome";
import Withdrawals from "../pages/Withdrawals/Withdrawals";
import Deposits from "../pages/Deposits/Deposits";
import Register from "../pages/Register/Register";
import Login from "../pages/Login/Login";
import Promotions from "../pages/Promotions/Promotions";
import PromotionDetails from "../pages/Promotions/PromotionDetails";
import StaticPage from "../pages/StaticPages/StaticPage";
import ChangePin from "../pages/ChangePin/ChangePin";
import AllSports from "../pages/AllSports/AllSports";
import TransactionHistory from "../pages/TransactionHistory/TransactionHistory";
import ForgotPin from "../pages/ForgotPin/ForgotPin";
import Profile from "../pages/Profile/Profile";
import VirtualLeague from "../pages/VirtualLeague/VirtualLeague";
import Jackpot from "../pages/Jackpot/Jackpot";
import RunningJackpot from "../pages/Jackpot/RunningJackpot";
import UpcomingJackpot from "../pages/Jackpot/UpcomingJackpot";
import SettledBets from "../pages/MyBets/SettledBets";
import SportsDetails from "../pages/AllSports/SportsDetails/SportsDetails";
import LeagueDetails from "../pages/LeagueDetails/LeagueDetails";
import Casinos from "../pages/Casinos/Casinos";
import OpenBets from "../pages/MyBets/OpenBets";
import FixtureDetails from "../pages/Home/FixtureDetails";
import FixtureLiveDetails from "../pages/Home/FixtureLiveDetails";
import MyBets from "../pages/MyBets/MyBets";
import CompleteJackpot from "../pages/Jackpot/CompleteJackpot";
import CommingSoon from "../components/Layouts/CommingSoon";
import Games from "../pages/Games/Games";
import Spribe from "../pages/Spribe/Spribe";
import BonusAwarded from "../pages/BonusAwarded/BonusAwarded";
import RefereAFriend from "../pages/RefereAFriend/RefereAFriend";
import Aviator from "../pages/Aviator/Aviator";
import Bigwins from "../pages/Bigwins/Bigwins";
import ProviderGames from "../pages/Bigwins/ProviderGames";

const publicRoutes = [
  { path: "/", exact: true, component: () => <Redirect to="/sports" /> },
  {
    path: "/Responsible-Gaming",
    component: StaticPage,
  },
  {
    path: "/Betting-Rules",
    component: StaticPage,
  },
  {
    path: "/Privacy-Policy",
    component: StaticPage,
  },
  {
    path: "/Cookies-Policy",
    component: StaticPage,
  },
  {
    path: "/Terms-and-Conditions",
    component: StaticPage,
  },
  {
    path: "/FAQ's",
    component: StaticPage,
  },

  {
    path: "/Contact-us",
    component: StaticPage,
  },
  {
    path: "/casinos",
    component: Casinos,
  },
  {
    path: "/comming-soon",
    component: CommingSoon,
  },

  { path: "/sports", component: Home },
  { path: "/withdrawals", component: Withdrawals },
  { path: "/Deposits", component: Deposits },
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-fontawesome", component: IconFontawesome },
  { path: "/register", component: Register },
  { path: "/login", component: Login },
  { path: "/transaction-history", component: TransactionHistory },
  { path: "/bonuses-awarded", component: BonusAwarded },
  { path: "/refer-a-friend", component: RefereAFriend },
  { path: "/change-pin", component: ChangePin },
  { path: "/AllSports", component: AllSports },
  { path: "/promotions", component: Promotions },
  { path: "/promotion-details/item", component: PromotionDetails },
  { path: "/forgot-pin", component: ForgotPin },
  { path: "/virtual-league", component: VirtualLeague },
  { path: "/jackpot", component: Jackpot },
  { path: "/jackpot-running/:id", component: RunningJackpot },
  { path: "/jackpot-complete/:id", component: CompleteJackpot },
  { path: "/jackpot-upcoming", component: UpcomingJackpot },
  { path: "/league-details", component: LeagueDetails },
  { path: "/Games", component: Games },
  { path: "/Spribe", component: Spribe },
  { path: "/Bigwins", component: Bigwins },
  { path: "/provider/:providerId", component: ProviderGames },
  { path: "/aviator", component: Aviator },
  {
    path: "/fixture-details/show",
    component: FixtureDetails,
  },
  {
    path: "/fixture-details/live/show",
    component: FixtureLiveDetails,
  },
  { path: "/sports-details", component: SportsDetails },
  { path: "/MyBets", component: MyBets },
];

const authProtectedRoutes = [{ path: "/profile", component: Profile }];

export { authProtectedRoutes, publicRoutes };
