import React, {useEffect, useState} from "react";
import PageHeader from "../../components/Common/PageHeader";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import QRCode from 'react-qr-code';
import {getRegulatoryAndParameters} from "../../helpers/repository";

function AffiliateLink(props) {
    let userId=JSON.parse(localStorage.getItem("user"))?.user?.id;
    const [size, setSize] = useState(256);
    const [link, setLink] = useState(`${window.location.origin}/register?ref=${userId}`)
    const [rafPercent, setRafPercent] = useState()
    const copied = () => {
        navigator.clipboard.writeText(link)
        $('.apply-p1').empty().show().text("Copied").css({'color':'#fff','background-color':'#333','text-align':'center','border-radius': '2px','width': 'max-content',
            'position': 'absolute','z-index': '1','padding': '8px 9px 8px 12px','margin': '-13px 0px 0px 5px'}).delay(2000).fadeOut(300);
    }
    useEffect(() => {
        getRegulatoryAndParameters(handleSuccess);
    }, []);

    const handleSuccess = (res) => {
        setRafPercent(res.raf_percent);
    };


    return (
        <>
            <div className="BLM-tab-pane active" id="BLM-Affiliate">
                <div className="affiliate-box">Welcome to our refer-a-friend/affiliate program, where you could earn {rafPercent}% of earnings generated by
                    your referrals. Use the QR Code for them scan and join directly from your phone or copy the link
                    below and share with your friends on social media
                </div>
            </div>
            <div className="affiliate-qr-div">
                <QRCode
                    title="Register"
                    value={link}
                    bgColor='#FFFFFF'
                    fgColor='#000000'
                    size={size === '' ? 0 : size}
                />
            </div>

            <div className="affiliate-qr-div" onClick={() =>  copied()}>

            <p className="affiliate-link-p">{link} <img className="link-img" src="/assets/images/SVG/link-solid.svg" /> <span className="apply-p1"></span></p>

            </div>

        </>
    )

}

export default withRouter(AffiliateLink)