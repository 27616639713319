import { get } from "lodash";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    getAllLeaguesBySport,
    getFixturesBySportIdCall,
    getFixturesByLeagueIdCall, getFixtureResultCall,
} from "../../helpers/repository";
import moment from "moment";

export const Filters = (props) => {
    const [leagues, setLeagues] = useState([]);

    // const [league, setLeague] = useState(
    //     props.isForLeague ? props.league_id : "AllLeagues"
    // );
    const [marketChange, setMarketChange] = useState(null);
    const today = new Date();
    const Sports = useSelector((s) => s?.Sports?.Index?.data ?? []);
    useEffect(() => {
        if (props?.isForLeague) props?.setLeague(props?.league_id);
    }, [props?.league_id]);

    useEffect(() => {
        if (props.sport_id) {
            getAllLeaguesBySport(
                props.sport_id,
                successGetLeaguesBySport,
                errorGetLeaguesBySport
            );
        }
    }, [props.sport_id]);

    useEffect(() => {
        if (Sports.length) {
            setMarketChange(
                _.sortBy(
                    Sports?.find((s) => s.id == props.sport_id)?.many_markets,
                    "pivot.id"
                )[0]?.id
            );
        }
    }, [props.sport_id, Sports]);

    const infiniteScroll = () => {
        // End of the document reached?
        if (
            window.innerHeight + document.documentElement.scrollTop ===
            document.documentElement.offsetHeight
        ) {
        }
    };

    const fetchData = () => {
        if (
            ![undefined, null, ""].includes(props.sport_id) &&
            props?.next_page_url != null
        ) {
            props?.setLoadMoreMessage("Loading Data...");

            let bb_ids = "";

            if(props.league == "TopLeagues") {
                bb_ids = leagues ?.map((l, index) => {
                    return l?.bb_id;
                })
                bb_ids.join(",")
            }

            props
                .getData(
                    props?.next_page_url,
                    props?.matchTime,
                    marketChange,
                    props?.perPage,
                    "&",
                    props?.resultTab,
                    bb_ids
                )
                .then((res) => {
                    let new_fixtures = get(res, "data.data.data", []);
                    let combined_fixtures = props.fixtures.concat(new_fixtures);

                    const unique_fixtures = combined_fixtures.filter((v,i,a)=>a.findIndex(t=>(t._id===v._id))===i)

                    props.set_fixtures(unique_fixtures)
                    props?.set_next_page_url(get(res, "data.data.next_page_url", null));

                    if (res?.data?.data?.data?.length == 0)
                        props.setLoadingMessage("No Data Available");
                })
                .finally(() => {
                    props?.setLoadMoreMessage("Load More Data");
                });
        }
    };

    useEffect(() => {
        fetchData();
    }, [props.trigger]);

    useEffect(() => {
        window.addEventListener("scroll", infiniteScroll);
    }, []);

    const successGetLeaguesBySport = (d) => {
        setLeagues(d);
    };
    const errorGetLeaguesBySport = () => {
        console.log("error");
    };

    useEffect(() => {
        if (marketChange) {
            if (props.league == "AllLeagues" || props?.isSetSport==true) {
                props.setLoadingMessage("Loading Data...");
                props
                    .getData(props.url, props?.matchTime, marketChange, props?.perPage)
                    .then((res) => {
                        props.set_fixtures(res?.data?.data?.data);
                        props?.set_next_page_url(res?.data?.data?.next_page_url);
                        if (res?.data?.data?.data?.length == 0)
                            props.setLoadingMessage("No Data Available");
                    })
                    .catch((e) => {
                        console.log(e);
                    })
                    .finally(() => {
                        props.setLoadingMessage(null);
                    });
            }else if(props.league == "TopLeagues" || props?.isLeagueSet==true) {
                props.setLoadingMessage("Loading Data...");
                let bb_ids= leagues ?.map((l, index) => {
                    return l?.bb_id;
                })
                getFixturesByLeagueIdCall(props.league, props?.matchTime, marketChange, bb_ids.join(","))
                    .then((res) => {
                        props.set_fixtures(res?.data?.data?.data);
                        props?.set_next_page_url(res?.data?.data?.next_page_url);
                        if (res?.data?.data?.data?.length == 0)
                            props.setLoadingMessage("No Data Available");
                    })
                    .catch((e) => {
                        console.log("error");
                    })
                    .finally(() => {
                        props.setLoadingMessage(null);
                    });
            }
            else if(props.league == "result") {
                props.setLoadingMessage("Loading Data...");
                //
                getFixtureResultCall(props?.sport_id, props?.matchTime, props.resultTab)
                    .then((res) => {
                        props.set_fixtures(res?.data?.data?.data);
                        props?.set_next_page_url(res?.data?.data?.next_page_url);
                        if (res?.data?.data?.data?.length == 0)
                            props.setLoadingMessage("No Data Available");
                    })
                    .catch((e) => {
                        console.log("error");
                    })
                    .finally(() => {
                        props.setLoadingMessage(null);
                    });
            }
            else if(props.league == "WorldCup") {
                props.setLoadingMessage("Loading Data...");

                getFixturesByLeagueIdCall(16, props?.matchTime, marketChange)
                    .then((res) => {
                        props.set_fixtures(res?.data?.data?.data);
                        props?.set_next_page_url(res?.data?.data?.next_page_url);
                        if (res?.data?.data?.data?.length == 0)
                            props.setLoadingMessage("No Data Available");
                    })
                    .catch((e) => {
                        console.log("error");
                    })
                    .finally(() => {
                        props.setLoadingMessage(null);
                    });
            }

        }
    }, [props?.league, props?.matchTime, marketChange, props.sport_id, props?.isLeagueSet]);

    useEffect(()=> {
        if(props?.league=="result"){
            props.setLoadingMessage("Loading Data...");
            getFixtureResultCall(props?.sport_id, props?.matchTime,props?.resultTab)
                .then((res) => {
                    props.set_fixtures(res?.data?.data?.data);
                    props?.set_next_page_url(res?.data?.data?.next_page_url);
                    if (res?.data?.data?.data?.length == 0)
                        props.setLoadingMessage("No Data Available");
                })
                .catch((e) => {
                    console.log("error");
                })
                .finally(() => {
                    props.setLoadingMessage(null);
                });
        }

    },[props?.resultTab]);
    useEffect(() => {
        props.setMarketChange(marketChange);
    }, [marketChange]);

    useEffect(() => {
        props.setMarketChange(marketChange);
    }, [marketChange]);
    return (
        <>
            {/*{*/}
            {/*    props.league!="result" ?*/}
            {/*        <div className="BLM-filterDropdowns">*/}
            {/*            <div className="BLM-select">*/}
            {/*                <select*/}
            {/*                    id="ddn_upcomingdayfilters"*/}
            {/*                    onChange={() => {*/}
            {/*                        props?.setMatchTime(*/}
            {/*                            document.getElementById("ddn_upcomingdayfilters").value*/}
            {/*                        );*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    <option selected={props?.matchTime == "all"} value="all">*/}
            {/*                        All*/}
            {/*                    </option>*/}
            {/*                    <option selected={props?.matchTime == "Today"} value="Today">*/}
            {/*                        Today*/}
            {/*                    </option>*/}
            {/*                    <option selected={props?.matchTime == "Tomorrow"} value="Tomorrow">*/}
            {/*                        Tomorrow*/}
            {/*                    </option>*/}
            {/*                    <option*/}
            {/*                        selected={props?.matchTime == "day_after"}*/}
            {/*                        value="day_after"*/}
            {/*                    >*/}
            {/*                        Day After*/}
            {/*                    </option>*/}
            {/*                </select>*/}
            {/*            </div>*/}
            {/*            {props.sport_id == Sports?.find((s) => s.name == "Soccer")?.id ? (*/}
            {/*                <div className="BLM-select">*/}
            {/*                    <select*/}
            {/*                        id="ddn_upcomingmarkets"*/}
            {/*                        onChange={() =>*/}
            {/*                            setMarketChange(*/}
            {/*                                document.getElementById("ddn_upcomingmarkets").value*/}
            {/*                            )*/}
            {/*                        }*/}
            {/*                    >*/}
            {/*                        {_.sortBy(*/}
            {/*                            Sports?.find((s) => s.id == props.sport_id)?.many_markets,*/}
            {/*                            "pivot.id"*/}
            {/*                        )*/}
            {/*                            .slice(0, 4)*/}
            {/*                            .map((m) => {*/}
            {/*                                return (*/}
            {/*                                    <option selected={marketChange == m.id} value={m.id}>*/}
            {/*                                        {m.name?.replaceAll("1x2", "1X2")}*/}
            {/*                                        /!*{m.name}*!/*/}
            {/*                                    </option>*/}
            {/*                                );*/}
            {/*                            })}*/}
            {/*                    </select>*/}
            {/*                </div>*/}
            {/*            ) : (*/}
            {/*                <div className="BLM-select">*/}
            {/*                    <select*/}
            {/*                        id="ddn_upcomingmarkets"*/}
            {/*                        onChange={() =>*/}
            {/*                            setMarketChange(*/}
            {/*                                document.getElementById("ddn_upcomingmarkets").value*/}
            {/*                            )*/}
            {/*                        }*/}
            {/*                    >*/}
            {/*                        {_.sortBy(*/}
            {/*                            Sports?.find((s) => s.id == props.sport_id)?.many_markets,*/}
            {/*                            "pivot.id"*/}
            {/*                        )*/}
            {/*                            .slice(0, 4)*/}
            {/*                            .map((m) => {*/}
            {/*                                return (*/}
            {/*                                    <option selected={marketChange == m.id} value={m.id}>*/}
            {/*                                        {m.name?.replaceAll("1x2", "1X2")}*/}
            {/*                                    </option>*/}
            {/*                                );*/}
            {/*                            })}*/}
            {/*                    </select>*/}
            {/*                </div>*/}
            {/*            )}*/}
            {/*        </div>*/}
            {/*        :*/}
            {/*        <div data-upcomingtimefilters="UpcomingMobileTimeFilterList" className="BLM-upcoming-filter-tabs">*/}
            {/*            <ul className="upcomingTimeList">*/}
            {/*                <li value="Today" className={`${props.resultTab=="today"?'active':''}`} onClick={() => {*/}
            {/*                    props.setResultTab('today')*/}
            {/*                }}>Today</li>*/}
            {/*                <li value="nextday" id="getnextdaydate" className={`${props.resultTab=="yesterday"?'active':''}`} onClick={() => {*/}
            {/*                    props.setResultTab('yesterday')*/}
            {/*                }}>{moment().subtract(1, 'days').format('D ddd')}</li>*/}
            {/*                <li value="nextafterday" id="getnextafterdaydate" className={`${props.resultTab=="day_before_yesterday"?'active':''}`} onClick={() => {*/}
            {/*                    props.setResultTab('day_before_yesterday')*/}
            {/*                }}>{moment().subtract(2, 'days').format('D ddd')}</li>*/}
            {/*                <li value="nextafterday" id="getnextafterdaydate" className={`${props.resultTab=="all"?'active':''}`} onClick={() => {*/}
            {/*                    props.setResultTab('all')*/}
            {/*                }}>Week</li>*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*}*/}

            {
                props?.toHideFilter!=true ?
                    <div className="container">
                        <div className="row d-flex">
                            <div className="com-sm col text-center filter-btn">
                                <button type="button" className={`btn btn-block w-100 btn-leagues ${props.league=="AllLeagues"?'active-legues':''}`}
                                        onClick={() => {
                                            props.setLeague('AllLeagues');
                                        }}
                                >All Leagues</button>
                            </div>
                            {/*<div className="com-sm col text-center filter-btn">*/}
                            {/*    <button type="button" className={`btn btn-block w-100 btn-leagues ${props.league=="WorldCup"?'active-legues':''}`}*/}
                            {/*            onClick={() => {*/}
                            {/*                props.setMatchTime("all")*/}
                            {/*                props.setLeague('WorldCup');*/}
                            {/*                }}*/}
                            {/*    ><i className='bx bx-trophy bx-flip-horizontal bx-tada'></i> World Cup</button>*/}
                            {/*</div>*/}
                            <div className="com-sm col text-center filter-btn">
                                <button type="button" className={`btn btn-block w-100 btn-leagues ${props.league=="TopLeagues"?'active-legues':''}`}
                                        onClick={() => {
                                            props.setLeague('TopLeagues');
                                        }}
                                >Top Leagues</button>
                            </div>
                            <div className="com-sm col text-center filter-btn">
                                <button type="button" className={`btn btn-block w-100 btn-leagues ${props.league == "result" ? 'active-legues' : ''}`} onClick={() => {
                                    props.setLeague('result');
                                }}>Results</button>
                            </div>
                            <div className="com-sm col text-center filter-btn">
                                        <div className="BLM-select">
                                            <select
                                                id="ddn_upcomingdayfilters"
                                                onChange={() => {
                                                    props?.setMatchTime(
                                                        document.getElementById("ddn_upcomingdayfilters").value
                                                    );
                                                }}
                                            >
                                                <option selected={props?.matchTime == "all"} value="all">
                                                    All
                                                </option>
                                                <option selected={props?.matchTime == "Today"} value="Today">
                                                    Today
                                                </option>
                                                <option selected={props?.matchTime == "Tomorrow"} value="Tomorrow">
                                                    Tomorrow
                                                </option>
                                                <option
                                                    selected={props?.matchTime == "day_after"}
                                                    value="day_after"
                                                >
                                                    Day After
                                                </option>
                                            </select>
                                        </div>
                            </div>

                        </div>
                    </div> :''
            }




        </>
    );
};
