import React from "react";
import { useSelector } from "react-redux";
import { getUrlParameter } from "../../../components/Common/Utils/helpers";
import { Fixtures } from "../../../components/Common/Fixtures";
import { useState, useEffect } from "react";
import { Filters } from "../../../components/Common/Filters";
import MessageBox from "../../../components/Common/MessageBox";
import Loader from "../../../components/Common/Loader";
import { getFixturesBySportIdCall } from "../../../helpers/repository";

function SportsDetailsTopLeague(props) {
  const [loadingMessage, setLoadingMessage] = useState("Loading Data...");
  const [loadMoreMessage, setLoadMoreMessage] = useState("Load More Data");
  const [next_page_url, set_next_page_url] = useState(null);
  const [fixtures, set_fixtures] = useState([]);
  // const [marketChange, setMarketChange] = useState("1");
  const [sportId, setSportId] = useState(null);
  const [trigger, setTrigger] = useState(0);
  const [marketChange, setMarketChange] = useState("1");
  const [matchTime, setMatchTime] = useState("All");

  useEffect(() => {
    // data?.map((sport)=>{
    setSportId(getUrlParameter("id").split("/")[0]);
    // })
  }, []);

  return (
      <>
        <div className="BLM-tab-pane active" id="BLM-sports-topLeagues">
          <Filters
              sport_id={sportId}
              fixtures={fixtures}
              setLoadingMessage={setLoadingMessage}
              setLoadMoreMessage={setLoadMoreMessage}
              set_fixtures={set_fixtures}
              setMarketChange={setMarketChange}
              getData={getFixturesBySportIdCall}
              url={`/v4/sports/${sportId}/fixtures`}
              perPage={20}
              next_page_url={next_page_url}
              set_next_page_url={set_next_page_url}
              trigger={trigger}
              matchTime={matchTime}
              setMatchTime={setMatchTime}
              toHideFilter={props?.toHideFilter}
              isSetSport={true}
          />

          <div className="BLM-inplay-content">
            {![undefined, null, ""].includes(loadingMessage) ? (
                <MessageBox data={loadingMessage} />
            ) : fixtures?.length ? (
                <Fixtures
                    marketId={marketChange}
                    fixtures={fixtures}
                    loadingMessage={loadingMessage}
                    loadMoreMessage={loadMoreMessage}
                    getData={getFixturesBySportIdCall}
                    url={`/v4/sports/${sportId}/fixtures`}
                    perPage={20}
                    next_page_url={next_page_url}

                />
            ) : (
                <MessageBox data={"No Data Available"} />
            )}
          </div>
          {next_page_url && (
              <div
                  onClick={() => {
                    setTrigger(trigger + 1);
                  }}
              >
                <MessageBox data={loadMoreMessage} />
              </div>
          )}
        </div>
      </>
  );
}

export default SportsDetailsTopLeague;
