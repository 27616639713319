import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export const FixturesJackpot = (props) => {
    const { isLive = false } = props;
    let { fixtures,fixtureCount } = props;


    fixtures = fixtures.sort(function (a, b) {
        return (
            new Date(parseInt(a.match_date.$date.$numberLong)) -
            new Date(parseInt(b.match_date.$date.$numberLong))
        );
    });
    const [leagues, setLeagues] = useState([]);
    const [exciseTax, setExciseTax] = useState(0);
    const [activeBaseline, setActiveBaseline] = useState(null);
    const [baseLines, setBaseLines] = useState([]);
    useEffect(() => {
        let allLeagues = fixtures?.map((f) => f?.tournament?.bb_id);
        allLeagues = [...new Set(allLeagues)];
        var union = allLeagues.filter(function(item){
            return leagues.indexOf(item) === -1;
        });
        var uniqLeague = leagues.concat(union);
        setLeagues(uniqLeague);
    }, [fixtures]);

    const handleFixtureClick = (fixture) => {
        // if (props.isLive) {
        //     history.push(`/fixture-details/live/show?id=${fixture._id}&is_live=1`);
        //     return;
        // }
        // history.push(`/fixture-details/show?id=${fixture._id}`);
    };

    const _addToBetslip = (fixture, fixtureMarket, odd) => {
        if(fixtureMarket?.length!=0 && fixture.fixture_markets){
            let copyFixture = _.cloneDeep(fixture);

            let oddsData = copyFixture?.fixture_markets?.find((fixtureMarketData) => fixtureMarketData?._id == fixtureMarket?._id)
                .fixture_market_odds.map((odd) => {
                    odd.selected = false;
                    return odd;
                });

            copyFixture.fixture_markets.find(
                (fixtureMarketData) => fixtureMarketData._id == fixtureMarket._id
            ).fixture_market_odds = oddsData;

            copyFixture.fixture_markets
                .find((fixtureMarketData) => fixtureMarketData._id == fixtureMarket._id)
                .fixture_market_odds.find(
                (oddData) => oddData._id == odd._id
            ).selected = true;

            let foundFixture = props.Bets.find((fix) => fix._id == fixture._id);
            if (foundFixture) {
                let d = _.cloneDeep(props.Bets);
                let index = d.findIndex((i) => i._id == fixture._id);
                if (index > -1) {
                    d[index] = copyFixture;
                }
                props.setBet(d)
            } else {
               props.setBet([...props.Bets, copyFixture])
            }
            var mustSelectedMatch=fixtureCount/3;
            var selectedMatches=props.Bets.length+1;
            if(mustSelectedMatch<=selectedMatches){
                props.setStake(100)
                props.setNoOfEntry(1)
            }
        }
    };

    const _removeOddFromBetslip = (fixture) => {
        props.setStake(0)
        props.setNoOfEntry(0)
        props.setBet(_.cloneDeep(props.Bets).filter((fd) => fd._id != fixture._id))
    };

    const checkIfOddIsSelected = (f, fm, odd) => {
        if (props.Bets.length && odd) {
            return (
                props.Bets.find((b) => b._id == f._id)
                    ?.fixture_markets?.find((fmd) => fmd._id == fm?._id)
                    ?.fixture_market_odds?.find((o) => o._id == odd._id)?.selected ??
                false
            );
        }
        return false;
    };

    const getActiveBaseLineOdds = (odds) => {
        return odds?.filter((o) => o.special_bet_value == activeBaseline);
    };
    const _renderStartingSoon = () => {
        return <button className="btn BLM-btn">Starting Soon</button>;
    };
    const _renderOdds = (f,index) => {
        let odds = f?.fixture_markets[0]?.fixture_market_odds ?? [];
        if (baseLines?.length) {
            odds = getActiveBaseLineOdds(odds ?? []);
        }
        return (
            <>
                <ul>
                    {odds?.map((o,key) => {
                        return (
                            <>
                                <li>

                                    <button
                                        odds-incdec="D"
                                        id="highlightBet_316625476"
                                        key-cnt={o.value != "OFF" ? `${fixtureCount += 1}` : ''}
                                        className={`btn-cls-cnt ${
                                            checkIfOddIsSelected(f, getFixtureMarket(f), o)
                                                ? `btn BLM-btnOdds active`
                                                : `btn BLM-btnOdds`
                                        }
                    ${o?.name == "OFF" ? "disabled" : ""} 
                    `}
                                        disabled={o ? (o.value == "OFF" ? true : false) : true}
                                        onClick={() => {
                                            if (checkIfOddIsSelected(f, getFixtureMarket(f), o)) {
                                                _removeOddFromBetslip(f, getFixtureMarket(f), o);
                                            } else {
                                                _addToBetslip(f, getFixtureMarket(f), o);
                                            }
                                        }}
                                    >
                                        <div className="BLM-marketOutcome">{o?.name}</div>
                                        {o.value == "OFF" ? "-" : parseFloat(o?.value)?.toFixed(2)}
                                    </button>
                                </li>
                            </>
                        );
                    })}
                </ul>
            </>
        );
    };

    const getFixtureMarket = (f) => {
        return f.fixture_markets.find((fm) => fm.market._id == props.marketId);
    };

    const getFixtureProviderOdds = (f) => {
        return getFixtureProvider(f)?.odds ?? [];
    };
    const getFixtureProvider = (f) => {
        return (
            f?.fixture_markets
                ?.find((fm, fmi) => fm.market_id == props.marketId)
                ?.fixture_market_providers?.find(
                (fmp, fmpi) => fmp.provider.name == "1XBet"
            ) ?? [0]
        );
    };

    const getUniqueBaseLines = (odds) => {
        let base_lines = odds
            .filter((o) => o.special_bet_value && o.special_bet_value != "-1")
            .map((o) => o.special_bet_value);
        return [...new Set(base_lines)];
    };

    const getUniqueArray = (array) => {
        return [...new Set(array)];
    };

    useEffect(() => {
        let basLineMust=["0.5","1.5","2.5","3.5"];
        let baseLines = [];
        fixtures?.forEach((f) => {
            let odds = f?.fixture_markets[0]?.fixture_market_odds ?? [];
            let base_lines = getUniqueBaseLines(odds);
            baseLines = baseLines.concat(base_lines);
        });
        var commonValues = _.intersection(basLineMust, baseLines);
        // console.log(commonValues);

        // baseLines = baseLines?.slice(0, 5);
        // baseLines = baseLines?.sort(function (a, b) {
        //   return a - b;
        // });
        // setBaseLines(_.uniq(baseLines) ?? []);
        setBaseLines(commonValues ?? [])
    }, [fixtures]);

    useEffect(() => {
        if (baseLines.length) setActiveBaseline(baseLines[0]);
    }, [baseLines]);
    const _renderBaseLines = () => {
        return (
            <>
                <div
                    className="BLM-subFilterButtons"
                    id="showSubMarketsList"
                    style={{ display: "block" }}
                >
                    <ul className="upcomingsubMarketList">
                        {baseLines?.length > 0
                            ? baseLines.map((bl) => {
                                return (
                                    <>
                                        <li
                                            className={activeBaseline == bl ? "active" : ""}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setActiveBaseline(bl);
                                            }}
                                        >
                                            TG {bl}
                                        </li>
                                    </>
                                );
                            })
                            : null}
                    </ul>
                </div>
            </>
        );
    };

    const getUniqueOddNames = (odds) => {
        let oddNames = odds?.map((odd) => odd.name);
        return [...new Set(oddNames ?? [])];
    };
    const _renderMarketNames = () => {
        let odds = fixtures[0]?.fixture_markets[0]?.fixture_market_odds ?? [];
        if (baseLines?.length) {
            odds = getActiveBaseLineOdds(odds ?? []);
        }
        return (
            odds?.map((o) => {
                return (
                    <>
                        <li>
                            <div className="BLM-marketItem">
                                {_extractMarketSpecName(o?.market_spec?.name??'')}
                            </div>
                        </li>
                    </>
                );
            }) ?? null
        );
    };
    const _extractMarketSpecName = (text) => {
        text = text.replaceAll("{$competitor1}", "1");
        text = text.replaceAll("draw", "X");
        text = text.replaceAll("{$competitor2}", "2");
        text = text.replaceAll("{total}", "");
        text = text.replaceAll("odd", "Odd");
        text = text.replaceAll("even", "Even");
        text = text.replaceAll("over", "Over");
        text = text.replaceAll("under", "Under");

        if (text == "u") {
            return "Under";
        } else if (text == "o") {
            return "Over";
        }
        return text;
    };
    return (
        <>
            {/*{_renderBaseLines()}*/}
            <div
                className="BLM-header"
                id="upcoming_1"
                onClick={() => {
                    window.toggleAccoridan(index);
                }}
            >
                <div  style={{width:'77%'}}>
                </div>
                <div className="BLM-leagueBox-header__marketInfo">
                    <div className="BLM-marketGroup">
                        <ul>{_renderMarketNames()}</ul>
                    </div>
                </div>
            </div>
            {leagues?.map((league, index) => {
                if (typeof(league) !== 'undefined' && league != null && fixtures?.find((f) => f.tournament_bb_id == league)) {
                    return (

                        <div className="BLM-leagueBox-group" key={index}>

                            <div
                                className="BLM-leagueBox BLM-accordion"
                                id={`getUpcomingAccordianId_${index}`}
                            >

                                <div className="BLM-accordion-content">
                                    <div className="BLM-leagueBox-content">
                                        {/* SECOND LOOP */}
                                        {fixtures
                                            .filter((f) => f.tournament_bb_id == league)
                                            .map((fixture) => {
                                                const participantOne = fixture.competitors[0];
                                                const participantTwo = fixture.competitors[1];
                                                if(fixture.competitors && fixture.competitors.length != 0 ) {
                                                    return (
                                                        <div className="BLM-matchBox" id={1} style={{
                                                            borderBottom: "unset"}}>
                                                            <div className="BLM-matchDetails-container">
                                                                <div
                                                                    className="BLM-matchDetails"
                                                                    onClick={() => {
                                                                        handleFixtureClick(fixture);
                                                                    }}
                                                                >
                                                                    <div className="BLM-matchBox__fixtureInfo">
                                                                        <div className="BLM-match__teamsInfo">
                                                                            <div className="BLM-match__teamName home truncate">
                                                                                {participantOne?.name}
                                                                            </div>
                                                                            <div className="BLM-match__teamName away truncate">
                                                                                {participantTwo?.name}
                                                                            </div>
                                                                        </div>
                                                                        <div className="BLM-match__scoreInfo">
                                                                            <div className="BLM-match__score">
                                    <span className="BLM-score active">
                                      {fixture?.score?.split(":")[0] ?? ""}
                                    </span>
                                                                            </div>
                                                                            <div className="BLM-match__score">
                                    <span className="BLM-score active">
                                      {fixture?.score?.split(":")[1] ?? ""}
                                    </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="BLM-outcomeDetails">
                                                                    <div className="BLM-btnOddsGroup">
                                                                        {isLive
                                                                            ? fixture?.bet_status != "started"
                                                                                ? _renderStartingSoon()
                                                                                : _renderOdds(fixture,index)
                                                                            : _renderOdds(fixture,index)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="BLM-matchBox__extraInfo" onClick={() => {
                                                                handleFixtureClick(fixture);
                                                            }}>
                            <span
                                className="BLM-match__kickOff"
                                eventid="home_upcoming_4042486"
                            >
                              {new Date(
                                  parseInt(fixture.match_date.$date.$numberLong)
                              ).toLocaleString("en-GB",{hour12: true ,day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit'})}
                            </span>

                                                                <span className="BLM-match__smsCode">
                              #<b>{fixture?.bb_id}</b>
                            </span>


                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            })}

                                        {/* SECOND LOOP */}
                                    </div>
                                </div>
                            </div>

                            {/* <MessageBox /> */}
                        </div>
                    );
                }
            })}
        </>
    );
};
