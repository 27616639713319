import {
  GET_ALL_STATIC_PAGES,
  GET_ALL_STATIC_PAGES_RESPONSE_SUCCESS,
} from "./actionTypes";

export const getAllStaticPages = () => {
  return {
    type: GET_ALL_STATIC_PAGES,
  };
};
export const getAllStaticPagesResponseSuccess = (payload) => ({
  type: GET_ALL_STATIC_PAGES_RESPONSE_SUCCESS,
  payload: payload,
});
