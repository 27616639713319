import {
  GET_FIXTURE_BY_ID,
  GET_FIXTURE_BY_ID_RESPONSE_SUCCESS,
  GET_LIVE_FIXTURE_BY_ID,
  GET_LIVE_FIXTURE_BY_ID_RESPONSE_SUCCESS,
} from "./actionTypes";

export const getFixtureById = (id) => {
  return {
    type: GET_FIXTURE_BY_ID,
    id: id,
  };
};
export const getFixtureByIdResponseSuccess = (payload) => ({
  type: GET_FIXTURE_BY_ID_RESPONSE_SUCCESS,
  payload: payload,
});

export const getLiveFixtureById = (id) => {
  return {
    type: GET_LIVE_FIXTURE_BY_ID,
    id: id,
  };
};
export const getLiveFixtureByIdResponseSuccess = (payload) => ({
  type: GET_LIVE_FIXTURE_BY_ID_RESPONSE_SUCCESS,
  payload: payload,
});
