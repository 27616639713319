import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_ALL_LEAGUES } from "./actionTypes";
import { getAllLeaguesCall } from "../../helpers/repository";
import { getAllLeaguesResponseSuccess } from "./actions";
import { showErrorMessages } from "../../components/Common/Utils/helpers";

function* attemptGetAllLeagues() {
  try {
    const response = yield call(getAllLeaguesCall);
    yield put(getAllLeaguesResponseSuccess(response.data.data));
  } catch (error) {
    showErrorMessages(error);
  }
}

function* LeaguesSaga() {
  yield takeEvery(GET_ALL_LEAGUES, attemptGetAllLeagues);
}

export default LeaguesSaga;
