import React from "react";
import PageHeader from "../../components/Common/PageHeader";
import {currency} from "../../helpers/constants";

function UpcomingJackpot() {
  return (
      <>
        <div className="BLM-commonPage">
          <div className="BLM-commonPage-content-full-width BLM-common">
            <div className="NP-jackpot NP-JP-previousResults">
              <PageHeader title={"Upcoming Jackpot"} />
              <div className="NP-JP-market-group">
                <div className="NP-JP-market-group__header NP-JP-market__header-1x2">
                  <div className="NP-JP-market-group__header-left">
                    <img src="../images/soccer-icon.png" />
                    <span className="NP-JP-market-header__title">
                    1x2 Jackpots
                  </span>
                  </div>
                  <div className="NP-JP-market-group__header-right"></div>
                </div>
              </div>
              <div className="NP-JP-cardBanner play">
                <div className="NP-JP-cardBanner-container">
                  <div className="NP-cardBanner__header">
                    <div className="NP-cardBanner__title">
                      Supa Sunday - 09 January 2022
                    </div>
                    <div className="NP-cardBanner__status" id="jpclose">
                      Closes in
                      <span
                          id="NP-countDown"
                          className="NP-cardBanner__countdown"
                          jackpot-datetime="01/09/2022 01:00:00 PM"
                      >
                      {" "}
                        <span>0</span>d <span>4</span>h <span>38</span>m{" "}
                        <span>8</span>s{" "}
                    </span>
                    </div>
                  </div>
                  <div className="NP-accordion collapsed NP-prizeMoneyAccordion">
                    <div
                        className="NP-accordion-header NP-cardBanner__prizeMoney-container"
                        id="PrizeList"
                    >
                      <div className="NP-cardBanner__prizeMoney">
                        <span className="NP-currency">{currency}</span>
                        <span
                            className="NP-prizeValue"
                            id="stake49"
                            attr-prize={5000000}
                        >
                        {` 5,000,000`}
                      </span>
                        <span
                            className="NP-prizeValue d-none"
                            id="stake25"
                            attr-prize={2500000}
                        >
                        2,500,000
                      </span>
                      </div>
                      <div
                          className="NP-iconSvg NP-iconInfo"
                          onclick="showdescription(event)"
                      >
                        <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            width="30px"
                            height="30px"
                            viewBox="0 0 24 24"
                            style={{ enableBackground: "new 0 0 30 30" }}
                            xmlSpace="preserve"
                        >
                          <path
                              id="info---material"
                              className="path-circle"
                              d="M12,0c2.2,0,4.2,0.5,6.1,1.7c1.8,1,3.2,2.5,4.3,4.3S24,9.8,24,12s-0.5,4.2-1.7,6.1
					   c-1,1.8-2.5,3.2-4.3,4.3c-1.8,1.1-3.8,1.6-6,1.6s-4.2-0.5-6.1-1.7c-1.8-1-3.2-2.5-4.3-4.3S0,14.2,0,12s0.5-4.2,1.7-6.1
					   c1-1.8,2.5-3.2,4.3-4.3S9.8,0,12,0z"
                          />
                          <path
                              className="path-i"
                              d="M13.1,8.5V6h-2.4v2.4h2.4V8.5z M13.1,18v-7.1h-2.4V18H13.1z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="NP-accordion-content">
                      <div className="NP-cardBanner__description">
                      <span
                          className="d-none"
                          id="bonus49"
                          attr-prize={5000000}
                      >
                        Prizes for runners up and most predictions - Jackpot
                        will be shared if more than 1 winner
                      </span>
                        <span
                            className="d-none"
                            id="bonus25"
                            attr-prize={2500000}
                        >
                        Prizes for runners up and most predictions - Jackpot
                        will be shared if more than 1 winner
                      </span>
                      </div>
                    </div>
                  </div>
                  <div className="NP-cardBanner__footer">
                    <div className="NP-cardBanner__extraInfo">
                      <span>1X2</span> |<span>13 Legs</span>|
                      <span>{currency}25/{currency}49</span>
                    </div>
                    {/* <div class='NP-cardBanner__actions'></div> */}
                  </div>
                </div>
              </div>
              <div className="NP-selection">
                <div className="NP-selectionButtons">
                  <ul className="fillterSelections">
                    <li onclick="getluckypick(event)">Lucky Pick</li>
                    <li onclick="getfavoritepick(event)">Favorite Pick</li>
                  </ul>
                </div>
                <div className="NP-selectionActions">
                  <ul>
                    <li
                        className="NP-selectionActions__clear"
                        onclick="getClear()"
                    >
                      Clear
                    </li>
                    <li className="NP-selectionActions__help">?</li>
                  </ul>
                </div>
              </div>
              <div className="NP-JP-gameSelection">
                <div className="NP-leagueBox">
                  <div className="NP-leagueBox-header">
                    <div className="NP-leagueBox-header__left">
                      <span className="NP-MarketName truncate">Match Result</span>
                    </div>
                    <div className="NP-leagueBox-header__right">
                      <div className="NP-outcomeGroup">
                        <ul>
                          <li>
                            <div className="NP-outcomeItem">Home</div>
                          </li>
                          <li>
                            <div className="NP-outcomeItem">Draw</div>
                          </li>
                          <li>
                            <div className="NP-outcomeItem">Away</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="NP-leagueBox-content">
                    <div className="NP-matchBox">
                      <div
                          className="NP-matchBox-content"
                          attr-matchid={4090547}
                          attr-jackpotid={1687}
                          attr-marketids={1}
                      >
                        <div className="NP-matchDetails">
                          <div className="NP-match__serialNumber">1</div>
                          <div className="NP-matchBox__matchInfo">
                            <div className="NP-matchBox__fixtureInfo ">
                              <div className="NP-match__teamsInfo">
                              <span className="NP-match__teamName home">
                                Rayo{" "}
                              </span>
                                <span className="NP-vs">v</span>
                                <span className="NP-match__teamName away">
                                {" "}
                                  Betis
                              </span>
                              </div>
                            </div>
                            <div className="NP-matchBox__extraInfo">
                            <span
                                className="NP-match__matchMinute"
                                jackpot-datetime="01/09/2022 01:00:00 PM"
                            >
                              Today 18:00
                            </span>
                            </div>
                          </div>
                          {/*<div id='NP_matchStats' class='NP-match__stats'>
				<div class='NP-iconSvg'>
					<svg version='1.1' id='stats-icon'
						xmlns='http://www.w3.org/2000/svg'
						xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
                                                    viewBox='0 0 10 11' style='enable-background:new 0 0 10 11;'
                                                    xml:space='preserve'>
						<path class='st0' d='M0,3h2v8H0V3z M4,0h2v11H4V0z M8,7h2v4H8V7z' />
					</svg>
				</div>
			</div>*/}
                        </div>
                        <div className="NP-outcomeDetails">
                          <div className="NP-btnOddsGroup">
                            <ul>
                              <li
                                  attr-marketid={1}
                                  attr-outcomeid={1}
                                  attr-outcomename="Home"
                                  attr-odds="2.50"
                              >
                                <button
                                    className="btn NP-btnOdds"
                                    onclick="stakeSelection(4090547,1,0,this)"
                                >
                                  2.50
                                </button>
                              </li>
                              <li
                                  attr-marketid={1}
                                  attr-outcomeid={2}
                                  attr-outcomename="Draw"
                                  attr-odds="3.50"
                              >
                                <button
                                    className="btn NP-btnOdds"
                                    onclick="stakeSelection(4090547,2,1,this)"
                                >
                                  3.50
                                </button>
                              </li>
                              <li
                                  attr-marketid={1}
                                  attr-outcomeid={3}
                                  attr-outcomename="Away"
                                  attr-odds="2.80"
                              >
                                <button
                                    className="btn NP-btnOdds"
                                    onclick="stakeSelection(4090547,3,2,this)"
                                >
                                  2.80
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="NP-selection NP-JP-stakeSelection">
                  <div className="NP-selectionButtons NP-selectionButtons-large">
                    <ul className="placebetstakelist">
                      <li
                          onClick={() => {
                            setintialStake(`${currency} 25`);
                          }}
                          attr-stake={25}>
                        {currency} 25
                      </li>
                      <li
                          onClick={() => {
                            setintialStake(`${currency} 49`);
                          }}
                          attr-stake={49}
                          className="active"
                      >
                        {currency} 49
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="NP-JP-gameSelection-footer">
                  <div className="NP-contentBlock d-none">
                    <div className="NP-leftContent">Free Bet:</div>
                    <div className="NP-rightContent" id="Freebet">
                      <div className="NP-select">
                        <select
                            id="freebetlist"
                            // onchange="getfreebetstakeamount(event)"
                        >
                          <option>Select</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="NP-contentBlock">
                    <div className="NP-leftContent">Total Stake:</div>
                    <div className="NP-rightContent" id="Totalstake">
                      <span className="NP-txtHighlight-primary">0</span>
                    </div>
                  </div>
                  <div className="NP-contentBlock">
                    <div className="NP-leftContent">No. of Entries:</div>
                    <div className="NP-rightContent" id="TotalEntries">
                      <span className="NP-txtHighlight-primary">0</span>
                    </div>
                  </div>
                  <div className="NP-contentBlock">
                    <div className="NP-leftContent">Max. Winnings:</div>
                    <div className="NP-rightContent" id="Totalamount">
                      {`${currency} 5000000`}
                    </div>
                  </div>
                  <div className="NP-contentBlock d-none">
                    <div className="NP-leftContent">WHT:</div>
                    <div className="NP-rightContent" id="TotalTax">
                      <span className="NP-txtHighlight-primary" />
                    </div>
                  </div>
                  <div className="NP-msgBox NP-error d-none">
                    <div id="message" />
                  </div>
                  <button
                      className="btn NP-btnSecondary active NP-btnExtraLarge NP-btnJP-placeBet"
                      id="disableplaynowbtnclick"
                      onclick="placejockpot(event)"
                  >
                    Play Now
                  </button>
                </div>
                <div
                    className="NP-JP-gameSelection__previousResults"
                    id="PlacebetPreviousResults"
                    style={{ display: "block" }}
                >
                  <a href="/ViewResult/Result?configid=111">
                    <span>Previous Results</span>
                  </a>
                </div>
                <div
                    className="NP-JP-previousResults__myJackpots"
                    id="PlacebetJackpotHistory"
                    style={{ display: "none" }}
                >
                  <a href="../Members/myjackpots">
                    <span>Jackpot History</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default UpcomingJackpot;
