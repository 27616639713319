import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { imagePath } from "./constants";

const firebaseConfig = {
  apiKey: "AIzaSyAodLCcA9ryMmwxhmjQys-KOEkvWAVrwV0",
  authDomain: "betta1-zm.firebaseapp.com",
  projectId: "betta1-zm",
  storageBucket: "betta1-zm.appspot.com",
  messagingSenderId: "255708666484",
  appId: "1:255708666484:web:218eb5fc5a9be36a894f21",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

onMessage(messaging, (payload) => {
  const { title, body, click_action } = payload.data;
  // Show notification
  const notificationOptions = {
    title: title,
    body: body,
    icon: "/assets/images/PNG/betta1-logo.svg",
    data: {
      click_action: click_action,
    },
  };

  if (payload.data.image) {
    notificationOptions.image = imagePath("campaigns", payload.data.image);
  }
  if (Notification.permission === "granted") {
    const notification = new Notification(title, notificationOptions);
    // Handle notification click
    notification.onclick = (event) => {
      event.preventDefault(); // Prevent the browser from focusing the Notification's tab
      window.open(notificationOptions.data.click_action, "_blank");
    };
  }
});

export {firebaseConfig, messaging, getToken, onMessage };
