import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import MessageBox from "../../components/Common/MessageBox";

import { Fixtures } from "../../components/Common/Fixtures";
import { Filters } from "../../components/Common/Filters";

import { getFixturesBySportIdCall } from "../../helpers/repository";
import { getNoDataMessage } from "../../helpers/utils";
import {ResultFixture} from "../../components/Common/ResultFixture";
function HomeUpcoming(props) {
  // const [marketChange, setMarketChange] = useState("1");
  const [loadingMessage, setLoadingMessage] = useState("Loading Data...");
  const [loadMoreMessage, setLoadMoreMessage] = useState("Load More Data");
  const Sports = useSelector((state) => state.Sports?.Index?.data);
  const [sportId, setSportId] = useState(null);
  const [trigger, setTrigger] = useState(0);
  // const [matchTime, setMatchTime] = useState("Today");
  const [league, setLeague] = useState("AllLeagues");
  const [resultTab, setResultTab] = useState("today");
  useEffect(() => {
    setSportId(Sports?.find((s) => s.name == "Soccer")?.id);
  }, [Sports]);

  return (
    <>
      <div className="BLM-tab-pane active" id="Upcoming">
        <div className="BLM-home-upcoming">
          <Filters
            sport_id={sportId}
            league={league}
            setLeague={setLeague}
            resultTab={resultTab}
            setResultTab={setResultTab}
            fixtures={props?.fixtures}
            isForLeague={false}
            setLoadingMessage={setLoadingMessage}
            setLoadMoreMessage={setLoadMoreMessage}
            set_fixtures={props?.set_fixtures}
            setMarketChange={props.setMarketChange}
            getData={getFixturesBySportIdCall}
            url={`/v4/sports/${sportId}/fixtures`}
            perPage={30}
            next_page_url={props?.next_page_url}
            set_next_page_url={props?.set_next_page_url}
            trigger={trigger}
            matchTime={props.matchTime}
            setMatchTime={props.setMatchTime}
          />
          <div className="BLM-home-upcoming-content">
            {![undefined, null, ""].includes(loadingMessage) ? (
              <MessageBox data={loadingMessage} />
            ) : props?.fixtures?.length ? (
                (
                    (league == "result") ?
                          <ResultFixture
                              marketId={props.marketChange}
                              fixtures={props?.fixtures}
                              pageName={"upcoming"}
                              loadingMessage={loadingMessage}
                              loadMoreMessage={loadMoreMessage}
                              getData={getFixturesBySportIdCall}
                              url={`/v4/sports/${sportId}/fixtures`}
                              perPage={30}
                              next_page_url={props?.next_page_url}
                              isLive={false}
                          />
                          :
                          <Fixtures
                              marketId={props.marketChange}
                              fixtures={props?.fixtures}
                              pageName={"upcoming"}
                              loadingMessage={loadingMessage}
                              loadMoreMessage={loadMoreMessage}
                              getData={getFixturesBySportIdCall}
                              url={`/v4/sports/${sportId}/fixtures`}
                              perPage={30}
                              next_page_url={props?.next_page_url}
                              isLive={false}
                          />
                )
            ) : (
              <MessageBox
                data={`No Data Available ${getNoDataMessage(props.matchTime)}`}
              />
            )}
          </div>
          {props?.next_page_url && (
            <div
              onClick={() => {
                setTrigger(trigger + 1);
              }}
            >
              <MessageBox data={loadMoreMessage} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default withRouter(HomeUpcoming);
