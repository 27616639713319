import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllStaticPages } from "../../store/StaticPages/actions";
function ContentFooter(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllStaticPages());
  }, []);

  const PageTypes = useSelector((state) => state?.staticPages.Index ?? []).map(
    (sp) => sp["page_type"]
  );

  const socialMediaData = [
    {
      platform: "Facebook",
      url: "https://www.facebook.com/betta1online?mibextid=ZbWKwL",
      iconSrc: "/assets/images/PNG/facebook.svg",
    },
    {
      platform: "Twitter",
      url: "https://twitter.com",
      iconSrc: "/assets/images/PNG/twitter.svg",
    },
    {
      platform: "Instagram",
      url: "https://www.instagram.com/betta1official?igsh=dW10ZWdvNmJhMHc1",
      iconSrc: "/assets/images/PNG/instagram.svg",
    },
  ];

  return (
    <>
      <div className="BLM-footer">
        <div className="BLM-footer-container">
          <div className="BLM-footer-logo"></div>
          <div className="BLM-footer-linksList">
            <ul>
              {PageTypes.map((pt, index) => (
                <li key={index}>
                  <Link to={pt?.replace(/ /g, "-")}>{pt}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="BLM-divider"></div>
          <p>
            Interactive Media & Gaming Limited, Republic of Zambia, The Betting
            Control Act No. BC 301 (# 0000359)
          </p>
          {/* <p>AIRTEL Phone: +260 770929358
              Whatsapp: +260 770959909</p>
            <p>MTN Phone: +260 765192341
              Whatsapp: +260 769550007</p> */}
          <p>Support: 0978282768 / 0968740657</p>
          <p>
            <a href="care@betta1.com" target="_blank">
              care@betta1.com
            </a>
          </p>
          <p>You have to be 18+ years and above to bet</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {socialMediaData.map((socialMedia, index) => (
            <a
              key={index}
              href={socialMedia.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={socialMedia.iconSrc}
                alt={socialMedia.platform}
                style={{ width: "30px", height: "30px", marginRight: "10px" }}
              />
            </a>
          ))}
        </div>
      </div>
    </>
  );
}

export default ContentFooter;
