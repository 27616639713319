import React, { useState, useEffect } from "react";
import TabMenu from "../../../components/Common/TabMenu";
import SportsDetailsLive from "./SportsDetailsLive";
import SportsDetailsTopLeague from "./SportsDetailsTopLeague";
import PageHeader from "../../../components/Common/PageHeader";
import { useSelector } from "react-redux";
import { getLiveFixturesBySportIdCall, getLiveSportCall } from "../../../helpers/repository";
import {useLocation, useParams} from "react-router-dom";

function SportsDetails(props) {
  const Sports = useSelector((s) => s.Sports?.Index?.data ?? []);
  const [id, setId] = useState('');
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const idFromUrl = searchParams.get('id');
    setId(idFromUrl);
    set_sport_id(idFromUrl);
  }, [location.search]);

  const [sportId, set_sport_id] = useState(id);
  const [live_sports_count, set_live_sports_count] = useState(0);

  useEffect(() => {
    if(sportId) {
      getLiveSportCall(sportId).then((res) => {
        set_live_sports_count(res?.data?.data?.live);
      });
    }
   
  }, [sportId]);

  const activeTab = useSelector(
    (state) => state?.CommonState?.activeTab ?? "TopLeagues"
  );

  const [active_tab, set_active_tab] = useState("TopLeagues");
  const tabs = [
    // {
    //   id: "All",
    //   title: "All Countries",
    //   activeClass: active_tab == "All" ? "active" : "",
    //   tabHighlightText: "",
    //   tabHighlightId: "",
    // },
    {
      id: "TopLeagues",
      title: "Top Leagues",
      activeClass: active_tab == "TopLeagues" ? "active" : "",
      tabHighlightText: "",
      tabHighlightId: "",
    },
    {
      id: "LiveNow",
      title: "Live Now",
      activeClass: active_tab == "LiveNow" ? "active" : "",
      tabHighlightText: `(${live_sports_count})`,
      tabHighlightId: "BLM-highlight",
    },
  ];
  return (
    <>
      <PageHeader
        title={Sports?.find((s) => s.id == sportId)?.name}
        showBack={true}
      />
      <TabMenu
        tabs={tabs}
        active_tab={active_tab}
        set_active_tab={set_active_tab}
      />

      <div className="BLM-commonPage" key={id}>
        <div className="BLM-commonPage-content-full-width BLM-common">
          <div className="BLM-tabs-content">
            {active_tab == "LiveNow" && <SportsDetailsLive />}
            {active_tab == "TopLeagues" && <SportsDetailsTopLeague    toHideFilter={true} />}
            {/* {active_tab == "All" && <SportsDetailsAllCountries />} */}
          </div>
        </div>
      </div>
    </>
  );
}

export default SportsDetails;
