import React, {useEffect, useState} from "react";
import PageHeader from "../../components/Common/PageHeader";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {callAffiliateEarning} from "../../helpers/repository";

function AffiliateEarning(props) {
    const dispatch = useDispatch();
    const [earning, setEarning] = useState();
    const [totalReferral, setTotalReferral] = useState();
    const [totalEarning, setTotalEarning] = useState();

    useEffect(() => {
        var id=JSON.parse(localStorage.getItem("user"))?.user?.id;
        callAffiliateEarning(id).then((res) => {
            let sum = res?.data?.data
                .map((o) => o.uid)
                .reduce((a, c) => {
                    return a + c;
                });
            let sumEarning = res?.data?.data
                .map((o) => o.paid_amount)
                .reduce((a, c) => {
                    return Number(a) + Number(c);
                });
            setTotalReferral(sum)
            setTotalEarning(sumEarning)
            setEarning(res?.data?.data)

        }).catch((e) => {



        });
    }, []);

    return (
        <>
            <div className="BLM-tab-pane active" id="BLM-Earning">
                <div className="row earning-tab">
                    <div className="col-md-6">Total Referrals ToDate: {totalReferral}</div>
                    <div className="col-md-6" style={{textAlign: "right"}}>Total Earning ToDate: {totalEarning}</div>
                </div>
                <div className="space-10" />
                <table className="table earning-table">
                    <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Unique Referrals</th>
                        <th scope="col">Earning Paid</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        (earning && earning.map((item,index) => {
                             return (
                               <>
                                   <tr key={index}>
                                       <td scope="row">{item?.day}</td>
                                       <td>{item?.uid}</td>
                                       <td>{item?.paid_amount}</td>
                                   </tr>
                               </>
                             );
                        }))
                    }

                    </tbody>
                </table>
            </div>
        </>
    )

}

export default withRouter(AffiliateEarning)