import { withRouter } from "react-router-dom";
import React, { useState } from "react";
import {currency} from "../../helpers/constants";

function JackpotDetails(props) {
  const [key, set_key] = useState("All");

  const getData = () => {
    if (key == "All") return props?.Jackpots;
    return props?.Jackpots.filter((item) => item?.market == key);
  };
  const _getPossibleWin = () => {
    let stakeAfterTax = stake - exciseTax;
    return (stakeAfterTax * totalOdds - WHT).toFixed(2);
  };
  return (
    <>
      <div className="BLM-filterDropdowns">
        <div className="BLM-select">
          <select
            id="ddn_leagues"
            onChange={(e) => {
              set_key(e.target.value);
            }}
          >
            <option value={"All"}>All</option>
            <option value={"1x2"}>1x2</option>
            <option value={"BTTS"}>BTTS</option>
            <option value={"u/0 2.5"}>u/0 2.5</option>
          </select>
        </div>
      </div>
      <div className="NP-tabs-content">
        <div className="NP-content">
          <div className="space-5"></div>
          <div className="NP-JP-market-group">
            <div className="NP-JP-market-group__content">
              {getData().map((jackpot) => {
                return (
                  <>
                    <div
                      className="NP-JP-cardBanner"
                      onclick='gotToPlayJackpot("/ViewResult/Result?configid=110", event)'
                    >
                      <div className="NP-JP-cardBanner-container">
                        <div className="NP-cardBanner__header">
                          <div className="NP-cardBanner__title">
                            {jackpot?.name}
                          </div>
                          <div className="NP-cardBanner__status">
                            Upcoming
                            <span
                              id="NP-countDown"
                              className="NP-cardBanner__countdown"
                              jackpot-datetime
                            />
                          </div>
                        </div>
                        <div className="NP-cardBanner__prizeMoney-container">
                          <div className="NP-cardBanner__prizeMoney">
                            <span className="NP-currency">{currency}</span>
                            <span
                              className="NP-prizeValue"
                              attr-prize={jackpot?.max_price}
                            >
                              {jackpot?.max_price}
                            </span>
                          </div>
                        </div>
                        <div className="NP-cardBanner__footer">
                          <div className="NP-cardBanner__bonusExtraInfo">
                            <div className={`NP-cardBanner__bonus`} id={1685}>
                              {" "}
                              {jackpot?.description}
                            </div>
                            <div className="NP-cardBanner__extraInfo">
                              {/*{console.log(jackpot)}*/}
                              <span>Market:{jackpot?.market}</span>
                              <br/>
                              <span>Minimum Stake:{jackpot?.price_per_ticket}</span>
                              {/*<span>{jackpot?.max_price}</span>*/}
                            </div>
                          </div>
                          <div className="NP-cardBanner__actions">
                            <button
                              className="btn NP-btnPrimary NP-btnSmall active NP-btnPlay"
                              onClick={() => {
                                props.history.push(`/jackpot-running/${jackpot?.id}`);
                              }}
                            >
                              View Jackpot
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default withRouter(JackpotDetails);
