import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Banners from "../../components/Common/Banners";
import PageHeader from "../../components/Common/PageHeader";
import {getLiveSportImgName, getSportName} from "../../components/Common/Utils/helpers";
function AllSports(props) {
  const Sports = useSelector((state) => state?.Sports.Index ?? []);
  const Banner = useSelector((state) => state?.Banner?.Index).filter(
    (banner) => banner["page_name"] == "All Sports"
  );

  return (
    <>
      <Banners Banner={Banner} />
      <PageHeader title={"All Sports"} showBack={false} />
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content BLM-common">
          <div className="BLM-categoryList">
            <div
              className="BLM-categoryListItem BLM-inPlay d-none"
              onClick={(event) => {
                window.highlightFooterTab(
                  event,
                  this.getAttribute("data-footer-inplay")
                );
                window.getInplay("Football");
              }}
              data-footer-inplay="In-play"
            >
              <div className="BLM-iconSvg">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 24 24"
                  style={{ enableBackground: "new 0 0 24 24" }}
                  xmlSpace="preserve"
                >
                  <path
                    d="M14.8,10.6l-5,3.3c-0.2,0.2-0.6,0-0.6-0.3V7c0-0.3,0.3-0.5,0.6-0.3l5,3.3C15,10.1,15,10.4,14.8,10.6z M22.6,4.8H1.4v10.9
                                                            h21.2V4.8 M22.7,3.4C23.4,3.4,24,4,24,4.7v11.1c0,0.7-0.6,1.3-1.3,1.3H1.3c-0.7,0-1.3-0.6-1.3-1.3V4.7C0,4,0.6,3.4,1.3,3.4H22.7
                                                            L22.7,3.4z M18,20v-0.5c0-0.3-0.3-0.6-0.6-0.6H6.6c-0.3,0-0.6,0.3-0.6,0.6V20c0,0.3,0.3,0.6,0.6,0.6h10.8C17.7,20.6,18,20.3,18,20z
                                                            "
                  />
                </svg>
              </div>
              Live Now
            </div>

            {Sports?.data?.map((sport, index) => (
              <>
                <div
                  className="BLM-categoryListItem"
                  value="australian rules"
                  onClick={() => {
                    props.history.push(`/sports-details?id=${sport.id}`);
                  }}
                  key={index + 1}
                >
                    <img className='live-sports-img' src={`assets/images/sporticons/${getLiveSportImgName(sport?.name)}.svg`} />
                  {/*<i className={`sb-icon_sport-${getSportName(sport?.name)}`} />*/}
                  {sport?.name}
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(AllSports);
