import {
  GET_FIXTURE_BY_ID_RESPONSE_SUCCESS,
  GET_LIVE_FIXTURE_BY_ID_RESPONSE_SUCCESS,
} from "./actionTypes";
import { combineReducers } from "redux";

const Index = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case GET_FIXTURE_BY_ID_RESPONSE_SUCCESS:
      return payload;

    case GET_LIVE_FIXTURE_BY_ID_RESPONSE_SUCCESS:
      return payload;

    default:
      return state;
  }
};

const FixtureDetail = combineReducers({
  Index,
});

export default FixtureDetail;
