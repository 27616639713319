import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {GET_ALL_BANNERS, GET_ALL_PRODUCTS} from "./actionTypes";
import {HeaderMenu} from "../../helpers/repository";
import { getAllProductsResponseSuccess } from "./actions";
import { showErrorMessages } from "../../components/Common/Utils/helpers";

function* attemptGetAllProducts() {
    try {
        const response = yield call(HeaderMenu);
        yield put(getAllProductsResponseSuccess(response.data.data));
    } catch (error) {
        showErrorMessages(error);
    }
}

function* ProductsSaga() {
    yield takeEvery(GET_ALL_PRODUCTS, attemptGetAllProducts);
}

export default ProductsSaga;
