import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_FIXTURE_BY_ID, GET_LIVE_FIXTURE_BY_ID } from "./actionTypes";
import {
  getFixtureByIdCall,
  getLiveFixtureByIdCall,
} from "../../helpers/repository";
import {
  getFixtureByIdResponseSuccess,
  getLiveFixtureByIdResponseSuccess,
} from "./actions";
import { showErrorMessages } from "../../components/Common/Utils/helpers";
import { startLoading, stopLoading } from "../common/actions";

function* attemptGetFixtureById(data) {
  const { id = 1 } = data;
  try {
    yield put(startLoading());
    const response = yield call(getFixtureByIdCall, id);
    yield put(getFixtureByIdResponseSuccess(response.data.data));
  } catch (error) {
    showErrorMessages(error);
  } finally {
    yield put(stopLoading());
  }
}

function* attemptGetLiveFixtureById(data) {
  const { id = 1 } = data;
  try {
    yield put(startLoading());
    const response = yield call(getLiveFixtureByIdCall, id);
    yield put(getLiveFixtureByIdResponseSuccess(response.data.data));
  } catch (error) {
    showErrorMessages(error);
  } finally {
    yield put(stopLoading());
  }
}

function* FixtureDetailSaga() {
  yield takeEvery(GET_FIXTURE_BY_ID, attemptGetFixtureById);
  yield takeEvery(GET_LIVE_FIXTURE_BY_ID, attemptGetLiveFixtureById);
}

export default FixtureDetailSaga;
