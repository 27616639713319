import React, { useEffect, useState } from "react";
import TabMenu from "../../components/Common/TabMenu";
// import { tabs } from "./data";
import { getAllPromotions } from "../../store/Promotions/actions";
import { useSelector, useDispatch } from "react-redux";
import { imagePath } from "../../helpers/constants";
import { activeClass } from "../../components/Common/Utils/helpers";
import { Link, withRouter } from "react-router-dom";
import { getAllJackpots } from "../../store/jackpots/actions";
import JackpotDetails from "./JackpotDetails";
import MessageBox from "../../components/Common/MessageBox";
import ResultDetail from "./ResultDetail";
import SportsDetailsLive from "../AllSports/SportsDetails/SportsDetailsLive";

function Jackpot(props) {
  const query = new URLSearchParams(props.location.search);
  const tab = query.get('tab')
  const dispatch = useDispatch();
  const [active_tab, set_active_tab] = useState(tab==2?"resultJackpot":"allJackpots");
  const [loadingMessage, setLoadingMessage] = useState("Loading Data...");

  useEffect(() => {
    dispatch(getAllJackpots());
  }, []);
  const Jackpots = useSelector((state) => state?.jackpots.Index ?? []);
  const tabs = [
    {
      id: "allJackpots",
      title: "All",
      activeClass: active_tab == "allJackpots" ? "active" : "",
      tabHighlightText: "",
      tabHighlightId: "",
    },
    {
      id: "resultJackpot",
      title: "Result",
      activeClass: active_tab == "resultJackpot"? "active" : "",
      tabHighlightText: "",
      tabHighlightId: "",
    },
  ];

  useEffect(() => {
    if (Jackpots?.length > 0)
    {setLoadingMessage(null)}
    else{
      setLoadingMessage("No data available")
    };
  }, [Jackpots]);

  return (
    <>
      <TabMenu
        tabs={tabs}
        active_tab={active_tab}
        set_active_tab={set_active_tab}
      />
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content-full-width BLM-common">
          <div className="BLM-tabs-content">
            {active_tab == "allJackpots" ?
              ![null, ""].includes(loadingMessage) ? (
              <MessageBox data={loadingMessage} />
              ) : Jackpots.length ? (
              <JackpotDetails Jackpots={Jackpots} />
              ) : null:''
            }
            {active_tab == "resultJackpot" && <ResultDetail/>}

          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Jackpot);
