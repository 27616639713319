import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_ALL_PROMOTIONS } from "./actionTypes";
import { getAllPromotionsCall } from "../../helpers/repository";
import { getAllPromotionsResponseSuccess } from "./actions";
import { showErrorMessages } from "../../components/Common/Utils/helpers";

function* attemptGetAllPromotions() {
  try {
    const response = yield call(getAllPromotionsCall);
    yield put(getAllPromotionsResponseSuccess(response.data.data));
  } catch (error) {
    showErrorMessages(error);
  }
}

function* PromotionsSaga() {
  yield takeEvery(GET_ALL_PROMOTIONS, attemptGetAllPromotions);
}

export default PromotionsSaga;
