import React, { useEffect, useState } from "react";
import Header from "../Common/Header";
import LeftMenu from "../Common/LeftMenu";
import { Link } from "react-router-dom";

import Footer from "../Common/Footer";
import { useSelector, useDispatch } from "react-redux";
import ContentFooter from "../Common/ContentFooter";

function AppLayout(props) {
  const showSideBar = useSelector(
    (state) => state?.CommonState?.showSideBar ?? false
  );

  return (
    <>
      <body className={`${showSideBar ? "BLM-sideMenu-opened" : ""}`}>
        <div className="SB-Lite-Mobile">
          {<Header />}
          <div className="BLM-layout-content">
            {props.children}
            <ContentFooter />
          </div>
          {<LeftMenu />}

          <Footer />
        </div>
      </body>
    </>
  );
}

export default AppLayout;
