import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { axiosApi, getSlotegratorGameCall } from "../../helpers/repository";
import Banners from "../../components/Common/Banners";
import ReactModal from "react-modal";
import Iframe from "react-iframe";
import ButtonLoader from "../../components/Common/ButtonLoader";
import MessageBox from "../../components/Common/MessageBox";
import { SERVER_URL } from "../../helpers/constants";

const Bigwins = () => {
  const [games, setGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [iframeURL, setIframeURL] = useState(null);
  const [providerNames, setProviderNames] = useState({});
  const [buttonLoader, setButtonLoader] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading Data...");
  const history = useHistory();
  const Banner = useSelector((state) =>
    state?.Banner?.Index.filter((banner) => banner["page_name"] === "Home Page")
  );

  // Function to fetch all games
  const fetchAllGames = async () => {
    try {
      const response = await axiosApi.get("slotegrator/AllGames");
      setFilteredGames(response?.data?.data || []);
      setLoadingMessage(null);
    } catch (error) {
      console.error("Error fetching all games:", error);
    }
  };

  // First useEffect to fetch game categories and providers
  useEffect(() => {
    const fetchData = async () => {
      setButtonLoader(true);
      try {
        const [gameTypesResponse, providerNamesResponse] = await Promise.all([
          axiosApi.get("slotegrator/game-type"),
          axiosApi.get("slotegrator/game-provider"),
        ]);

        const gameCategories = [
          { id: "All", name: "All" },
          ...gameTypesResponse?.data?.data,
        ];
        setGames(gameCategories);

        const names = providerNamesResponse.data.data.reduce(
          (acc, provider) => {
            acc[provider.id] = provider.name;
            return acc;
          },
          {}
        );
        setProviderNames(names);

        // Call fetchAllGames function to load all games
        await fetchAllGames();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setButtonLoader(false);
      }
    };

    fetchData();
  }, []);

  // Second useEffect to fetch games based on selected category
  useEffect(() => {
    const fetchGames = async () => {
      setButtonLoader(true);
      try {
        const url =
          selectedCategory === "All"
            ? "slotegrator/AllGames"
            : `slotegrator/AllGames?type=${selectedCategory}`;
        const response = await axiosApi.get(url);
        setFilteredGames(response?.data?.data || []);
        setLoadingMessage(null);
      } catch (error) {
        console.error("Error fetching games:", error);
      } finally {
        setButtonLoader(false);
      }
    };

    fetchGames();
  }, [selectedCategory]);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const groupGamesByProvider = () => {
    return filteredGames.reduce((acc, game) => {
      const providerId = game.provider_id || "Unknown Provider";
      if (!acc[providerId]) {
        acc[providerId] = [];
      }
      acc[providerId].push(game);
      return acc;
    }, {});
  };

  const groupedGames = groupGamesByProvider();

  const handleSeeAllClick = (providerId) => {
    history.push(`/provider/${providerId}`);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "67%",
      overflow: "hidden",
      padding: "20px",
    },
  };

  const handleGameClick = (game) => {
    getSlotegratorGameCall(game, successGetGameUrl, errorGetGameUrl);
  };

  const successGetGameUrl = (res) => {
    setIframeURL(decodeURIComponent(res));
  };

  const errorGetGameUrl = (error) => {
    console.error("Error fetching game URL:", error);
  };

  return (
    <>
      <Banners Banner={Banner} />
      <div
        className="BLM-sportsHeader BLM-scrollableArea BLM-horizontal"
        style={{
          display: "flex",
          overflowX: "auto",
          whiteSpace: "nowrap",
        }}
      >
        {games.map((game) => (
          <div
            key={game.id}
            onClick={() => handleCategoryClick(game.id)}
            className={`BLM-categoryListItem ${
              selectedCategory === game.id ? "selected" : ""
            }`}
            style={{
              padding: "10px",
              cursor: "pointer",
              backgroundColor: "transparent",
              color: selectedCategory === game.id ? "#68c303" : "#ffff",
              display: "flex",
              alignItems: "center",
              borderBottom:
                selectedCategory === game.id ? "4px solid #68c303" : "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                maxWidth: "100px",
              }}
            >
              <img
                src={`${SERVER_URL}/slotegrator/categories/${game.name}.png`}
                style={{
                  width: "auto",
                  height: "auto",
                  maxWidth: "100px",
                  padding: "1px",
                }}
                alt={game.name}
              />
              <div>{game.name}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content">
          <div className="container">
            {buttonLoader ? (
              <ButtonLoader />
            ) : (
              <div>
                {![null, ""].includes(loadingMessage) ? (
                  <MessageBox data={loadingMessage} />
                ) : (
                  Object.keys(groupedGames).map((providerId) => (
                    <div key={providerId} className="mt-3">
                      <div
                        className="accordion-button-container"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#00004D",
                          color: "white",
                          borderColor: "#00137f",
                          padding: "10px",
                        }}
                      >
                        <div className="accordion-button-text">
                          {providerNames[providerId] || providerId}
                        </div>
                        <button
                          onClick={() => handleSeeAllClick(providerId)}
                          style={{
                            color: "white",
                            border: "none",
                            background: "none",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          See All
                        </button>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#00004D",
                          padding: "20px",
                          cursor: "pointer",
                        }}
                      >
                        <div className="row img_row">
                          {groupedGames[providerId].slice(0, 6).map((game) => (
                            <div
                              className="col-lg-2 col-md-4 col-6 mt-2"
                              key={game.id}
                            >
                              <img
                                src={`${SERVER_URL}/slotegrator/slotegrator-games/${game.uuid}.png`}
                                alt={game.name}
                                style={{
                                  objectFit: "cover",
                                  borderRadius: "10px",
                                }}
                                onClick={() => handleGameClick(game.uuid)}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <ReactModal
        style={customStyles}
        isOpen={!!iframeURL}
        onRequestClose={() => setIframeURL(null)}
        className="Casino_Games"
      >
        <Iframe
          url={iframeURL}
          className="myClassname casino_game_iframe"
          display="initial"
          position="relative"
        />
      </ReactModal>
    </>
  );
};

export default Bigwins;
