import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_FIXTURES_BY_SPORT_ID } from "./actionTypes";
import { getFixturesBySportIdCall } from "../../helpers/repository";
import { getFixturesBySportIdsResponseSuccess } from "./actions";
import { showErrorMessages } from "../../components/Common/Utils/helpers";

function* attemptGetFixtureBySportId(data) {
  const { id = 1 } = data;
  try {
    const response = yield call(
      getFixturesBySportIdCall,
      `/v4/sports/${id}/fixtures`,
      "all",
      "1",
      "2",
      "?"
    );
    yield put(getFixturesBySportIdsResponseSuccess(response.data.data));
  } catch (error) {
    showErrorMessages(error);
  }
}

function* FixturesSaga() {
  yield takeEvery(GET_FIXTURES_BY_SPORT_ID, attemptGetFixtureBySportId);
}

export default FixturesSaga;
