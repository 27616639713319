import { GET_ALL_SPORTS, GET_ALL_SPORTS_RESPONSE_SUCCESS } from "./actionTypes";

export const getAllSports = () => {
  return {
    type: GET_ALL_SPORTS,
  };
};
export const getAllSportsResponseSuccess = (payload) => ({
  type: GET_ALL_SPORTS_RESPONSE_SUCCESS,
  payload: payload,
});
