import parse from "html-react-parser";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import PageHeader from "../../components/Common/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { imagePath } from "../../helpers/constants";

function PromotionDetails(props) {
  const urlParams = new URLSearchParams(props.location.search);

  const PromotionDetail = useSelector(
    (state) => state?.promotions.Index ?? []
  ).find((promotion) => promotion?.id === +urlParams.get("id"));

  return (
    <>
      <PageHeader title={PromotionDetail?.header} showBack={true} />
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content-full-width BLM-common">
          <div className="BLM-promotion-item">
            <div className="BLM-promo-banner">
              <img src={imagePath("banners", PromotionDetail?.banner)} />
            </div>
            <div className="BLM-promo-content BLM-content">
              <div className="BLM-promo-title">
                {`${PromotionDetail?.header}`}
              </div>
              <div className="BLM-promo-description BLM-instructionsPage BLM-instructions">
                <p>{`${PromotionDetail?.short_body}`}</p>
                <div className="BLM-instructions-title">
                  To qualify, the following terms and conditions apply;
                </div>
                {parse(PromotionDetail?.description)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(PromotionDetails);
