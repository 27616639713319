import React, { useState, useEffect } from "react";
import Iframe from "react-iframe";
import ReactModal from "react-modal";

// ------------------------------------------ virt --------------------------------
import { useDispatch, useSelector } from "react-redux";
import Banners from "../../components/Common/Banners";
import { getAllCasinoGames, getGameUrl } from "../../helpers/repository";
import { showMessage } from "../../components/Common/Toaster";
import MessageBox from "../../components/Common/MessageBox";

const VirtualLeague = () => {
  const [arr, setArr] = useState([]);
  const [iframeURL, setIframeURL] = useState(null);
  const [gameTypes, setGameTypes] = useState([]);
  const [selectedGameType, setSelectedGameType] = useState(null);
  const [types, setTypes] = useState([
    {
      old_name: "RGS - VSB",
      new_name: "Virtuals",
    },
    {
      old_name: "Scratch card",
      new_name: "Scratch Cards",
    },
  ]);
  const [loadingMessage, setLoadingMessage] = useState("Loading Data...");

  useEffect(() => {
    getAllCasinoGames(successGetCasinoGames, errorGetCasinoGames);
  }, []);

  const successGetCasinoGames = (data) => {
    setGameTypes(data);
  };

  const errorGetCasinoGames = () => {
    console.log("something went wrong.");
  };

  useEffect(() => {
    if (gameTypes.length > 0) {
      setSelectedGameType(
        gameTypes.find((gt) => gt.description == "RGS - VSB").id
      );
      setLoadingMessage(null);
    }
  }, [gameTypes]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
    },
  };

  useEffect(() => {
    var myArr = [];
    var mynumber = 159;
    for (var i = 1; i <= mynumber; i++) {
      myArr.push(i);
    }

    setArr(myArr);
  }, []);

  const handleCasinosChange = () => {
    setSelectedGameType(document.getElementById("ddn_type").value);
  };

  const Banner = useSelector((state) => state?.Banner?.Index).filter(
    (banner) => banner["page_name"] == "Casino Page"
  );

  const _openGameUrl = (id) => {
    getGameUrl(id, successGetGameUrl, errorGetGameUrl);
  };

  const successGetGameUrl = (res) => {
    setIframeURL(res.gameURL);
  };

  const errorGetGameUrl = (error) => {
    showMessage("error", error);
  };

  return (
    <>
      {/*<div style={{textAlign:"center",marginTop:"40px",marginBottom:"40px"}}>*/}
      {/*<h1>Coming Soon</h1>*/}
      {/*</div>*/}
      <Banners Banner={Banner} />
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content BLM-common">
          <div className="container">
            <div className="row mb-2">
              <div className="BLM-select">
                <select id="ddn_type" onChange={handleCasinosChange}>
                  {types.map((gt) => {
                    return (
                      <option
                        value={
                          gameTypes?.find((g) => g.description == gt.old_name)
                            ?.id ?? "Table Games"
                        }
                      >
                        {gt.new_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            {
              <div className="row img_row">
                <ReactModal
                  // style={customStyles}
                  isOpen={iframeURL}
                  onRequestClose={() => setIframeURL(null)}
                  className="Virtual_Games"
                >
                  <Iframe
                    url={iframeURL}
                    // width="450px"
                    // height="80%"
                    id="myId"
                    className="myClassname virtual_game_iframe"
                    display="initial"
                    position="relative"
                    overflow="hidden"

                  />
                </ReactModal>
                {![null, ""].includes(loadingMessage) ? (
                  <MessageBox data={loadingMessage} />
                ) : (
                  gameTypes
                    ?.find((gt) => gt.id == selectedGameType)
                    ?.games?.map((single) => {
                      return (
                        <div
                          className="col-lg-3 col-6 mt-2"
                          onClick={() => {
                            _openGameUrl(single.game_id);
                          }}
                        >
                          <img
                            src={`https://gamlabs.prerelease-env.biz/game_pic/rec/160/${single.game_id}.png`}
                          />
                        </div>
                      );
                    })
                )}
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};
export default VirtualLeague;
