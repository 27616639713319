import { GET_FIXTURES_BY_SPORT_ID_RESPONSE_SUCCESS } from "./actionTypes";
import { combineReducers } from "redux";

const Index = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case GET_FIXTURES_BY_SPORT_ID_RESPONSE_SUCCESS:
      return payload;

    default:
      return state;
  }
};

const FixturesBySport = combineReducers({
  Index,
});

export default FixturesBySport;
