import {
  GET_ALL_BANNERS,
  GET_ALL_BANNERS_RESPONSE_SUCCESS,
} from "./actionTypes";

export const getAllBanners = () => {
  return {
    type: GET_ALL_BANNERS,
  };
};
export const getAllBannersResponseSuccess = (payload) => ({
  type: GET_ALL_BANNERS_RESPONSE_SUCCESS,
  payload: payload,
});
