import {
  GET_ALL_LOCATIONS,
  GET_ALL_LOCATIONS_RESPONSE_SUCCESS,
} from "./actionTypes";

export const getAllLocations = () => {
  return {
    type: GET_ALL_LOCATIONS,
  };
};
export const getAllLocationsResponseSuccess = (payload) => ({
  type: GET_ALL_LOCATIONS_RESPONSE_SUCCESS,
  payload: payload,
});
