import React from "react";

function Hamburger() {
  return (
    <>
      <div className="hamburger"></div>
      <div className="hamburger"></div>
      <div className="hamburger"></div>
    </>
  );
}

export default Hamburger;
